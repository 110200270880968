<template>
    <v-card class="card-aula">
        <v-row class="d-flex align-content-between flex-wrap">
            <v-col cols="10" class="nome-aula">
                {{aula.descricao}}
            </v-col>
            <v-col cols="2" style="padding-left: 0px ;">
                <v-icon v-on:click="selecionaAula(aula)" :class="aula.ativo ? 'active' : 'inactive'">mdi-circle</v-icon>
            </v-col>
        </v-row>
    </v-card>
</template>
<style scoped>
    .active{
        color:#0A83F3 !important;
    }
    .inactive{
        color:#D9D9D9 !important;
    }
    .nome-aula{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 17.4867px;
        line-height: 21px;
        padding-left: 20px;
        color: #000000;
    }
    .card-aula{
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 14.5723px 14.5723px 14.5723px 14.5723px;
    }
    @media only screen and (max-width: 600px) {
        .nome-aula{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 9.4867px;
            line-height: 11px;
    
            color: #000000;
        }
    }
</style>
<script>
    export default {
        name: "ModalAulas",
        data() {
            return {
               selecionada: false 
            };
        },
        props:{
            aula:{
                type: Object,
                required: true
            }
        },
        methods: {
            selecionaAula(aula){
                this.selecionada = !this.selecionada
                this.$emit('aulasSelecionadas', aula.id)
            },
        },
    }
</script>