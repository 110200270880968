<template>
    <v-bottom-navigation height="100px">
        <v-btn v-on:click="redirectTo('/home')">
            <span class="btn-footer">Home</span>
            <v-icon color="#fff">mdi-home</v-icon>
        </v-btn>
    
        <v-btn v-on:click="redirectTo('/diagnostico')">
            <span class="btn-footer">Diagnóstico</span>
            <v-icon color="#fff">mdi-clipboard-search-outline</v-icon>
        </v-btn>
    
        <v-btn>
            <span class="btn-footer">Plano <br/>de estudos</span>
            <v-icon color="#fff">mdi-school-outline</v-icon>
        </v-btn>

        <v-btn v-on:click="redirectTo('/home')">
            <span class="btn-footer">Aulas</span>
            <v-icon color="#fff">mdi-monitor-account</v-icon>
        </v-btn>

        <v-btn>
            <span class="btn-footer"  v-on:click="offline()">Sair</span>
            <v-icon color="#fff">mdi-close</v-icon>
        </v-btn>
        
    </v-bottom-navigation>
</template>

<style scoped>

    .v-bottom-navigation{
        background-color: #0259F3;
        position: fixed;
        bottom: 0px;
    }
    .btn-footer{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18.1942px;
        line-height: 25px;
        color: #FFFFFF;
    }
    @media only screen and (max-width: 600px) {
        .btn-footer{
            font-size: 10px;
        }
    }
</style>
<script>
import client from '@/services/http/client/client';
export default {
    name: "FooterPage",
    data() {
        return {
           
        }
    },
    mounted(){
    },
    methods:{
        redirectTo(url){
            location.href = url;
        },
        offline(){
            client.post('/auth/logout')
                .then(() => {
                    localStorage.removeItem('token')
                    location.href = '/login';
                })
                .catch(() => {
                    localStorage.removeItem('token')
                    location.href = '/login';
                })
                .finally(() => {
                    location.href = '/login';
                });
        }
    },  
    props:{
    }
}
</script>