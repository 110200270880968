<template>
    <div class="row">
        <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-between link">
                <img v-if="showBack" src="/assets/img/arrow-left.svg" class="img-arrow" style="cursor:pointer;" v-on:click="redirect()"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "HeaderPage",
    data() {
        return {
            
        }
    },
    mounted(){
    },
    created(){
    },
    methods:{
        redirect(){
            location.href = this.urlBack;
        }
    },  
    props:{
        showBack:{
            type: Boolean,
            required: false,
            default: false
        },
        urlBack:{
            type: String,
            required: false,
            default: ''
        }
    }
}
</script>