<template>
    <v-card elevation="2" class="card-software d-flex align-items-center" v-on:click="Assistir()">
        <v-col cols="12" class="d-flex justify-content-between">
            <span class="texto-nivel">{{aula.titulo}}</span>
            <font-awesome-icon icon="fa-solid fa-check" v-if="aula.assistida" color="#229A00"/>
        </v-col>
    </v-card>
</template>

<style scoped>
    .card-software{
        background: #FFFFFF;
        border-radius: 11.0245px;
        height: 75.6px;
    }
    .texto-nivel{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18.8991px;
        line-height: 23px;
        color: #4A4A4A;
    }
   
</style>
<script>
export default {
    name: "CardAulaLista",
    data() {
        return {
           
        }
    },
    mounted(){
        
    },
    methods:{
        Assistir(){
            location.href = '/busca-aulas/' + this.aula.id;
        }
    },  
    props:{
        aula:{
            type:Object,
            required: true,
        },
        softwareId:{
            type:Number,
            required: true
        }
    }
}
</script>