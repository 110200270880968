<template>
    <div>
        <div class="container pt-4">
            <header-component :showBack="true" :urlBack="'/selecao-nivel/' + this.softwareId"/>

            <div class="box-carousel" v-if="loading">
                <v-row class="text-center">
                    <v-col>
                        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
                    </v-col>
                </v-row>
            </div>

            <div class="box-carousel" v-if="!loading">
                <v-row>
                    <h3 class="title">Tópicos / Vídeo Aulas</h3>
                </v-row>
                <v-row>
                    <span class="subtitle">Agora é só escolher o tópico que que você precisa aprender ou evoluir e acessar as aulas específicas. <br>
                        Precisa de algum tópico especifico para o seu negócio? Clique em 
                        <v-btn icon  title="Contato" v-on:click="contato()">
                        <v-avatar color="indigo">
                            <font-awesome-icon icon="fa-regular fa-envelope" color="#0259F3" style="width:25px;"/>
                        </v-avatar>
                    </v-btn>
                        e envie-nos sua solicitação
                    </span>
                </v-row>
                <v-row>
                    <v-col class="mt-2" cols="6" sm="3" md="3" v-for="item in lstDiagnostico" :key="item.software.id">
                        <lista-software-card-lista-software :icone="item.software.icone" :qtdAulas="item.software.aulas.length" v-if="showCard" />
                    </v-col>
                </v-row>
                <v-row>
                    <span class="subtitle mt-3 mb-3"><strong>Nível {{this.nivel.descricao}} - Tópicos</strong></span>
                </v-row>
                <v-row v-if="lstAulas.length > 0">
                    <v-col cols="12" v-for="item in lstAulas" :key="item.aulas_id" style="cursor:pointer">
                        <card-aula-lista :aula="item" :software-id="softwareId"/>
                    </v-col>
                </v-row>

                <v-row v-if="lstAulas.length == 0">
                    <v-col cols="12">
                        <b-alert variant="danger" show>Não Há Tópicos cadastrados para este Nível</b-alert>
                    </v-col>
                </v-row>
               
                
            </div>
            
        </div>
    </div>
</template>
<style scoped>
    .input-search, .v-text-field__slot{
        border: none;
        border-radius: 17.3394px !important;
    }
    .color-arrow{
        fill: '#004FF3f' !important;
    }
    .link{
        cursor: pointer;
    }
    .img-arrow{
        width: 100%;
    }
    .btn-no-knows{
        background: #C4C4C4 !important;
        border-radius: 16.1035px !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 27px !important;
        text-align: center !important;
        height: 52.36px !important;
        color: #000000 !important;
    }
    .btn-no-knows-active{
        background: #fff !important;
        border-radius: 16.1035px !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 27px !important;
        text-align: center !important;
        height: 52.36px !important;
        color: #004FF3 !important;
        border: 2px inset #004FF3 !important;
    }
    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 40.2589px;
        line-height: 49px;

        color: #4A4A4A;
    }
    .subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20.7657px;
        line-height: 31px;

        color: #4A4A4A;
    }
    .btn-next{
        background: #004FF3 !important;
        border-radius: 11.1394px !important;
        color: #fff !important;
        height: 52.36px !important;
    }
    body{
        background: red !important;
    }
    @media only screen and (max-width: 600px) {
        .title{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 900;
            font-size: 20.2589px;
            line-height: 20px;
    
            color: #4A4A4A;
        }
        .subtitle{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16.7657px;
            line-height: 18px;
    
            color: #4A4A4A;
        }
        .img-arrow{
            width: 100%;
        }
    }
    .test{
        background-color: #A0A0A0 !important;
    }
</style>
<script>
import client from '@/services/http/client/client';
export default {
    name: "ListaAulas",
    data() {
        return {
            showCard: false,
            lstDiagnostico: [],
            lstNiveis: [],
            lstAulas: [],
            softwareId: 0,
            nivel: {},
            loading:true
        }
    },
    mounted(){
        this.loading = true
        this.BuscaSoftware()
        this.BuscaNiveis()
        this.softwareId = this.$route.params.software_id
        this.nivelId = this.$route.params.nivel_id
        
    },
    created(){
        require('../../../../public/assets/css/home-aluno.css');
    },
    methods:{
        BuscaSoftware(){
            client.get('/diagnostico-by-student?all=1')
                .then( response => {
                    this.lstDiagnostico = response.data;
                    this.lstDiagnostico = this.lstDiagnostico.filter(item => item.software.id == this.softwareId)

                    this.lstAulas = this.lstDiagnostico[0].software.aulas;
                    this.lstAulas = this.lstAulas.filter(item => item.nivel_id == this.nivelId)
                    if (this.lstDiagnostico.length == 1) {
                        this.showCard = true
                    }
                })
                .catch(() => {
                    location.href = '/login';
                })
        },
        BuscaNiveis(){
            client.get('/niveis')
                .then( response => {
                    this.lstNiveis = response.data;
                    this.nivel = this.lstNiveis.filter(item => item.id == this.nivelId)
                    if (this.nivel.length > 0) {
                        this.nivel = this.nivel[0]
                        this.loading = false
                    }
                });
        },
        BuscaAulas(nivel){
            location.href = '/lista-aulas/'+this.softwareId+'/'+nivel
        },
        contato(){
            this.$bvModal.show('contato')
        },
       
    }
}
</script>