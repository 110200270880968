<template>
    <div style="background-color:#F0F0F0">
        <v-card-title class="text-h5 grey lighten-2">
            <v-row>
                <v-col cols="12">
                    <span class="title">Nível de Conhecimento:</span>
                </v-col>
                <v-col cols="12">
                    <span class="sub-title">Você poderá ver todo conteúdo referente ao <br/>software escolhido.</span>
                </v-col>
                
            </v-row>
        </v-card-title>

        <v-card-text>
            <span class="sub-title-info">
                Selecione abaixo os tópicos que você já sabe utilizar. Não esqueça de SALVAR antes de sair da tela. <br/>
                Se tem dúvida sobre algum tópico deixe em branco. Depois você poderá fazer uma revisão desses tópicos ao acessar as vídeo aulas.
            </span>
            <div v-for="aula in lstAulas" :key="aula.id">
                <modal-card-aula :aula="aula" v-on:aulasSelecionadas="aulasSelecionadas"></modal-card-aula>
            </div>
            
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="primary"
            text
            :loading="loadingBtn"
            v-on:click="alterModal()"
            >
                Salvar
            </v-btn>

        </v-card-actions>
    </div>
</template>
<style scoped>
    
    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 32.059px;
        line-height: 39px;
        /* identical to box height */
        color: #4A4A4A;
    }
    .sub-title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 17.4867px;
        line-height: 21px;

        color: #000000;
    }
    .sub-title-info{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 13.4867px;
        line-height: 8px;

        color: #000000;
    }
    @media only screen and (max-width: 600px) {
        .title{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 900;
            font-size: 22.059px;
            line-height: 15px;
            /* identical to box height */
            color: #4A4A4A;
        }
        .sub-title{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 10.4867px;
            line-height: 0px;
            color: #000000;
        }
        .sub-title-info{
            font-size: 10.4867px;
            line-height: 8px;
        }
    }
</style>
<script>
    import client from '@/services/http/client/client';
    export default {
        name: "ModalAulas",
        data() {
            return {
                lstAulas: [],
                lstAulasSelecionadas: [],
                loadingBtn:false
            };
        },
        props:{
            software:{
                type: Object,
                required: true
            },
        },
        mounted() {
            this.fetchAulas();
        },
        methods: {
            fetchAulas(){
                client.get('/aulas-by-software/' + this.software.id)
                    .then( response => {
                        this.lstAulas = response.data;
                    });
            },
            aulasSelecionadas(id){
                this.lstAulas = this.lstAulas.map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            ativo: !item?.ativo
                        }
                    }

                    return item
                });
                this.lstAulasSelecionadas = this.lstAulas.filter(function (el){
                    return el.ativo ==true ;
                });

                this.$emit('calculaPercentual',  this.lstAulas.length, this.lstAulasSelecionadas.length)
            },
            alterModal(){
                this.storeAulas();
            },
            storeAulas(){
                this.loadingBtn = true;
                if (this.lstAulasSelecionadas.length > 0) {
                    client.post('aulas/assistida',{
                        lstAulas: this.lstAulasSelecionadas,
                        software_id: this.software.id
                    })
                    .then( () => {
                        this.loadingBtn = false;
                        this.$bvModal.hide('modalAulas_'+this.software.id)
                    })
                    .catch(function () {
                        this.loadingBtn = false;
                    })
                } else {
                    this.loadingBtn = false;
                    this.$bvModal.hide('modalAulas_'+this.software.id)
                }
                
            }
        },
    }
    </script>