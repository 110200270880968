<template>
    <div :class="software.ativo ? 'card card-active' : 'card'">
        <div class="row text-center">
            <div class="col-12 text-center">
                <v-img :src="software.icone" height="150px" width="100%" :alt="software.nome"/>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .card{
        background: #FFFFFF;
        border-radius: 16.1035px;
        border-color: transparent !important;
        cursor: pointer;
    }
    .card:hover{
        border-color: #C4C4C4 !important;
    }

    .card-active{
        border: 2px inset #004FF3 !important;
    }
    .card-active:hover{
        border: 2px inset #004FF3 !important;
    }
    
</style>
<script>
    export default {
        name: "CardSoftware",
        props:{
            software:{
                type: Object,
                required: true
            }
        },
        created(){
        },
        data() {
            return {
                
            }
        },

    }
</script>