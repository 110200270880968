<template>
    <div>
        <v-row style="d-flex text-center ajustaLinha">
            <v-col cols="1" md="2" class="barra b1">
                <v-img src="/assets/img/racing-flag-1.svg" class="flag" v-if="percentual <= 12.5"></v-img>
            </v-col>
            <v-col class="barra b2">
                <v-img src="/assets/img/racing-flag-1.svg" class="flag" v-if="percentual >= 12.6 && percentual <= 25.1"></v-img>
            </v-col>
            <v-col class="barra b3">
                <v-img src="/assets/img/racing-flag-1.svg" class="flag" v-if="percentual >= 25.2 && percentual <= 37.7"></v-img>
            </v-col>
            <v-col class="barra b4">
                <v-img src="/assets/img/racing-flag-1.svg" class="flag" v-if="percentual >= 37.8 && percentual <= 50.3"></v-img>
            </v-col>
            <v-col class="barra b5">
                <v-img src="/assets/img/racing-flag-1.svg" class="flag" v-if="percentual >= 50.4 && percentual <= 62.9"></v-img>                
            </v-col>
            <v-col class="barra b6">
                <v-img src="/assets/img/racing-flag-1.svg" class="flag" v-if="percentual >= 63 && percentual <= 75.5"></v-img>                
            </v-col>
            <v-col class="barra b7">
                <v-img src="/assets/img/racing-flag-1.svg" class="flag" v-if="percentual >= 75.6 && percentual <= 88.1"></v-img>
            </v-col>
            <v-col class="barra b8">
                <v-img src="/assets/img/racing-flag-1.svg" class="flag" v-if="percentual >= 88.2" ></v-img>
                <v-img src="/assets/img/target.svg" class="target" ></v-img>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
    @media only screen and (max-width: 600px) {
        .flag{
            margin-top:-30px !important;
        }
        .target{
            margin-top:-30px !important;
        }

        .ajustaLinha{
            margin-top: -500px !important;
        }
        .barra{
            background: linear-gradient(180deg, #004FF3 0%, rgba(0, 79, 243, 0) 100%);
            border-radius: 9.22279px 9.22279px 0px 0px;
            margin-left:10px;
            min-width: 34.27px;
            max-width: 30.27px;
        }

        .b8{
            margin-top: 290px !important;
        }
        .b7{
            margin-top: 320px !important;
        }
        .b6{
            margin-top: 350px !important;
        }
        .b5{
            margin-top: 380px !important;
        }
        .b4{
            margin-top: 410px !important;
        }
        .b3{
            margin-top: 440px !important;
        }
        .b2{
            margin-top: 470px !important;
        }
        .b1{
            margin-top: 500px !important;
        }

    }

    .inicio{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        margin-top:20px;
    }

    .inicio-8{
        margin-top:80px !important;
    }
    .flag{
        width: 40.27px !important;
        margin-top:-60px;
    }
    .target{
        width: 40.27px !important;
        margin-top:-90px;
    }
    
    .barra{
        background: linear-gradient(180deg, #004FF3 0%, rgba(0, 79, 243, 0) 100%);
        border-radius: 9.22279px 9.22279px 0px 0px;
        margin-left:10px;
        
       
    }
    .b8{
        margin-top: 100px;
    }
    .b7{
        margin-top: 160px;
    }
    .b6{
        margin-top: 220px;
    }
    .b5{
        margin-top: 280px;
    }
    .b4{
        margin-top: 340px;
    }
    .b3{
        margin-top: 400px;
    }
    .b2{
        margin-top: 460px;
    }
    .b1{
        margin-top: 490px;
    }
</style>
<script>
export default {
    //CADA BARRA 12,5%
    //assets/img/racing-flag-1.svg
    //assets/img/target.svg
    name: "GraficoDiagnostico",
    data() {
        return {
            
        }
    },
    mounted(){
    },
    created(){
    },
    methods:{

    },  
    props:{
        percentual:{
            type: Number,
            required: true,
        },
    }
}
</script>