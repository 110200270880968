import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import router  from './router/index.js'
import vSelect from 'vue-select'
import VModal from 'vue-js-modal'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheck, faHeart, faHeartBroken, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import { faHeart as faHeartRegular, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faBell } from '@fortawesome/free-regular-svg-icons'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from 'vue-moment';

Vue.config.productionTip = false

Vue.use(VModal)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.component('home-screen', require('./components/Home/HomeScreen.vue').default);
Vue.component('login-screen', require('./components/Home/LoginScreen.vue').default);
Vue.component('registro-form', require('./components/Registro/RegistroForm.vue').default);
Vue.component('step-1', require('./components/Registro/Step1.vue').default);
Vue.component('ensino-step-2', require('./components/Registro/EnsinoStep2.vue').default);
Vue.component('carousel-entrada', require('./components/CarouselEntrada/CarouselEntrada.vue').default);
Vue.component('software-selecao', require('./components/Software/SoftwareScreen.vue').default);
Vue.component('card-software', require('./components/Software/CardSoftware.vue').default);
Vue.component('software-selecao-finalizar', require('./components/Software/SoftwareFinalizarScreen.vue').default);
Vue.component('card-diagnostico', require('./components/Software/CardDiagnostico/CardDiagnostico.vue').default);
Vue.component('modal-aulas', require('./components/Software/ModalAulas/ModalAulas.vue').default);
Vue.component('modal-card-aula', require('./components/Software/ModalAulas/ModalCardAula.vue').default);
Vue.component('diagnostico-screen', require('./components/Diagnostico/DiagnosticoScreen.vue').default);
Vue.component('card-software-diagnostico', require('./components/Diagnostico/CardSoftwareDiagnostico.vue').default);
Vue.component('imagem-nivel-diagnostico', require('./components/Diagnostico/ImagemNivel.vue').default);
Vue.component('diagnostico-screen-detalhado', require('./components/Diagnostico/DiagnosticoScreenDetalhado.vue').default);
Vue.component('grafico-diagnostico', require('./components/Diagnostico/GraficoDiagnostico.vue').default);
Vue.component('footer-component', require('./components/Layout/Footer.vue').default);
Vue.component('header-component', require('./components/Layout/Header.vue').default);
Vue.component('home-aluno-screen', require('./components/Home/HomeAlunoScreen.vue').default);
Vue.component('lista-software-card-lista-software', require('./components/Home/ListaSoftware/CardListaSoftware.vue').default);
Vue.component('lista-software', require('./components/Home/ListaSoftware/ListaSoftware.vue').default);
Vue.component('lista-nivel', require('./components/Home/ListaNiveis/ListaNivel.vue').default);
Vue.component('card-nivel-lista', require('./components/Home/ListaNiveis/CardNivelLista.vue').default);
Vue.component('card-aula-lista', require('./components/Home/ListaAulas/CardAulaLista.vue').default);
Vue.component('lista-aulas', require('./components/Home/ListaAulas/ListaAulas.vue').default);
Vue.component('sala-de-aula', require('./components/SalaDeAula/SalaDeAula.vue').default);
Vue.component('card-aula-topico-lista', require('./components/Home/ListaAulasTopico/CardAulaTopicoLista.vue').default);
Vue.component('drawer-menu-list', require('./components/Layout/DrawerMenuList.vue').default);
Vue.component('drawer-menu-item', require('./components/Layout/DrawerMenuItem.vue').default);
Vue.component('perfil-aluno', require('./components/Perfil/PerfilAluno.vue').default);
Vue.component('contato-aluno', require('./components/Contato/Contato.vue').default);
Vue.component('inbox-aluno', require('./components/Contato/Inbox.vue').default);
Vue.component('inbox-respostas', require('./components/Contato/Respostas.vue').default);
Vue.component('termos-screen', require('./components/Termos/Termos.vue').default);
Vue.component('nova-senha', require('./components/ResetarSenha/NovaSenha.vue').default);

Vue.component('v-select-custom', vSelect)
Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faCheck)
library.add(faHeart)
library.add(faHeartRegular)
library.add(faHeartBroken)
library.add(faBell)
library.add(faEdit)
library.add(faEnvelope)
library.add(faEye)
Vue.use(moment);
Vue.use(vSelect);

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)

}).$mount('#app')
