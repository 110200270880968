<template>
  <div class="container pt-4">
      <div class="row">
          <div class="col-12 d-flex flex-row-reverse bd-highlight">
              <img src="assets/img/logo-white.png" class="img-logo-registro" alt="Logotipo branco"> 
          </div>
      </div>
      <div class="row">
          <div class="box-register">
              <v-row>
                  <v-col cols="12" class="text-center">
                      <h3 class="titulo-registro">Alteração de Senha!</h3>
                  </v-col>
                  <v-col cols="12">
                      <span class="descricao-registro">Digite o e-mail para o envio do código de confirmação para alterar a senha:</span>
                  </v-col>
                  <v-col cols="12">
                      <v-text-field
                          :hide-details="true"
                          :dark="true"
                          :light="true"
                          label="E-mail"
                          v-model="email"
                          outlined
                          type="email"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                      <v-btn :x-large="true" class="btn-lg col-12 btn-warning" :loading="loadingBtn" v-on:click="validar()">
                          ENVIAR CÓDIGO
                      </v-btn>
                      
                  </v-col>
              </v-row>
          </div>
          
      </div>
  </div>
</template>
<style scoped>
  .btn-warning{
      background-color: #EF7D00 !important;
      color: #FFFFFF;
      border-color: #EF7D00 !important;
  }
  .titulo-registro, .descricao-registro{
      color: #fff;
  }
</style>
<script>
  import client from '@/services/http/client/client';
  export default {
    name: "ResetarSenha",
    data () {
        return {
            name: null,
            email: null,
            password:null,
            loadingBtn:false,
        };
    },
    methods:{
      validar(){
        this.loadingBtn = true;
        client.post('auth/login/reset', {
            email: this.email,
        })
        .then((response) => {
            if (response.status === 200) {
                localStorage.setItem('email',this.email)
                location.href = '/validar-codigo/reset'
            }
        })
        .catch(() => {
            this.$swal.fire({
                icon: 'error',
                reverseButtons: true,
                title: 'Código inválido',
                confirmButtonText: 'Ok',
                showLoaderOnConfirm: true,
            }).then(() => {
                this.loadingBtn = false;
            })
        })
        .finally(() => {
            this.loadingBtn = false;
        });
        
      },
    },
    created(){
      document.getElementById('css').setAttribute('style','background-color:#004ff3 !important;')
    }
  }
</script>