<template>
    <div class="container pt-4">
        <div class="row">
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-between link" v-on:click="prev()">
                    <img src="assets/img/arrow-left.svg" class="img-arrow" />
                </div>
                <img src="assets/img/logo-blue.svg" class="img-logo-registro" alt="Logotipo branco">
            </div>
        </div>
        <div class="row">
            <div class="box-carousel">
                <v-container>
                    
                    <v-row>
                        <h3 class="title">{{titulo}}</h3>
                    </v-row>
                    <v-row class="mt-5">
                        <span class="subtitle">{{subtitulo}}</span>
                    </v-row>   
                    <div v-if="!this.showsSelected">
                        <v-row v-for="categoria in lstCategorias" :key="categoria.id">
                            <v-card class="mt-4" v-if="((lstSoftwares.filter(item => item.categorias.includes(categoria.id)).length > 0))">
                                <v-card-title>
                                    {{categoria.descricao}}
                                </v-card-title>
                                <v-card-content>
                                    <v-row class="d-flex">
                                        <v-col cols="6" md="3" v-for="software in lstSoftwares.filter(item => item.categorias.includes(categoria.id))" :key="software.id" v-on:click="selectSoftware(software.id)">
                                            <card-software :software="software"/>
                                        </v-col>
                                    </v-row>
                                </v-card-content>
                            </v-card>
                        </v-row>
                    </div>            

                    <div v-if="this.showsSelected">
                        <v-row v-for="categoria in lstCategorias" :key="categoria.id">
                            <v-card class="mt-4" v-if="((lstSofwareSelecionado.filter(item => item.categorias.includes(categoria.id)).length > 0))">
                                <v-card-title>
                                    {{categoria.descricao}}
                                </v-card-title>
                                <v-card-content>
                                    <v-row class="d-flex">
                                        <v-col cols="6" md="3" v-for="software in lstSofwareSelecionado.filter(item => item.categorias.includes(categoria.id))" :key="software.id" v-on:click="selectSoftwareFinal(software.id)">
                                            <card-software :software="software"/>
                                        </v-col>
                                    </v-row>
                                </v-card-content>
                            </v-card>
                        </v-row>
                    </div>     
                    
                    <div class="row d-flex mt-5 mb-5">
                        <div class="col-12 col-md-10">
                            <v-btn v-if="(page == 1)" :class="noKnows ? 'btn-no-knows-active' :'btn-no-knows'" block v-on:click="selectNaoConheco()"> 
                                Não conheço os softwares acima
                            </v-btn>
                        </div>
                        <div class="col-12 col-md-2 d-flex flex-row-reverse">
                            <v-btn class="btn-next" block v-on:click="next" :loading="loadingBtn" v-if="habilitaNext"> 
                                <v-img src="assets/img/next.svg" contain height="10"/>
                            </v-btn>
                        </div>
                    </div>
                </v-container>
                
            </div>
        </div>
        
   </div>
</template>
<style scoped>
    
    .color-arrow{
        fill: '#004FF3f' !important;
    }
    .link{
        cursor: pointer;
    }
    .img-arrow{
        width: 100%;
    }
    .btn-no-knows{
        background: #C4C4C4 !important;
        border-radius: 16.1035px !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 27px !important;
        text-align: center !important;
        height: 52.36px !important;
        color: #000000 !important;
    }
    .btn-no-knows-active{
        background: #fff !important;
        border-radius: 16.1035px !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 27px !important;
        text-align: center !important;
        height: 52.36px !important;
        color: #004FF3 !important;
        border: 2px inset #004FF3 !important;
    }
    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 40.2589px;
        line-height: 49px;

        color: #4A4A4A;
    }
    .subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 25.7657px;
        line-height: 31px;

        color: #4A4A4A;
    }
    .btn-next{
        background: #004FF3 !important;
        border-radius: 11.1394px !important;
        color: #fff !important;
        height: 52.36px !important;
    }
    body{
        background: red !important;
    }
    @media only screen and (max-width: 600px) {
        .title{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 900;
            font-size: 20.2589px;
            line-height: 20px;
    
            color: #4A4A4A;
        }
        .subtitle{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16.7657px;
            line-height: 18px;
    
            color: #4A4A4A;
        }
        .img-arrow{
            width: 100%;
        }
    }
    .test{
        background-color: #A0A0A0 !important;
    }
</style>
<script>
import client from '@/services/http/client/client';
    export default {
        name: "SoftwareScreen",
        data() {
            return {
                page: 1,
                lstSoftwares: [],
                lstCategorias: [],
                lstSoftwareCategoria: [],
                lstSofwareSelecionado: [],
                lstSoftwareTemp: [],
                noKnows: false,
                showsSelected: false,
                tokens:{
                    startDiagnostico: "Vamos começar seu diagnóstico, me conta",
                    ouviuFalar: "Qual software você já ouviu falar?",
                    entreSoftwares: "Entre os softwares que você selecionou",
                    sabeMexer: "Qual software você sabe mexer?"
                },
                titulo: "",
                subtitulo: "",
                loadingBtn: false,
                habilitaNext: false
            }
        },
        mounted() {
            this.fetchSoftwares();
            this.fetchCategorias();
            this.titulo = this.tokens.startDiagnostico;
            this.subtitulo = this.tokens.ouviuFalar;
        },
        created(){
            require('../../../public/assets/css/softwares.css');
        },
        methods:{
            fetchSoftwares(){
                client.get('/software-by-student')
                    .then( response => {
                        this.lstSoftwares = response.data;
                    });
                    
            },
            fetchCategorias(){
                client.get('/categorias-software')
                    .then( response => {
                        this.lstCategorias = response.data;
                    });
                    
            },
            storeDiagnostico(){
                this.loadingBtn = true;
                this.lstSofwareSelecionado = this.lstSofwareSelecionado.filter(item => item.ativo);
                client.post('/diagnostico/gravar',{
                    lstSoftware: this.lstSofwareSelecionado,
                })
                .then( response => {
                    this.loadingBtn = false;
                    this.$swal.fire({
                        icon: response.data.type,
                        reverseButtons: true,
                        title: response.data.msg,
                        confirmButtonText: 'Ok',
                        showLoaderOnConfirm: true,
                    }).then(() => {
                        this.loadingBtn = false;
                        if (this.lstSofwareSelecionado.length > 0) {
                            location.href = '/finaliza-seleciona-softwares'
                        } else {
                            location.href = '/diagnostico'
                        }
                        
                    })
                })
                .catch(function (response) {
                    this.$swal.fire({
                        icon: response.data.type,
                        reverseButtons: true,
                        title: response.data.msg,
                        confirmButtonText: 'Ok',
                        showLoaderOnConfirm: true,
                    }).then(() => {
                        this.loadingBtn = false;
                    })
                })
            },
            checkHabilitacao(){
                this.lstSofwareSelecionado = this.lstSoftwares.filter(item => item.ativo);
                return this.habilitaNext = (this.lstSofwareSelecionado.length > 0 || this.noKnows);
            },
            checkHabilitacaoFinal(){
                this.lstSofwareSelecionado = this.lstSofwareSelecionado.filter(item => item.ativo);
                return this.habilitaNext = (this.lstSofwareSelecionado.length > 0 || this.noKnows);
            },
            selectSoftware(id){
                this.noKnows = false;
                this.lstSoftwares = this.lstSoftwares.map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            ativo: !item?.ativo
                        }
                    }

                    return item
                });
                this.checkHabilitacao();
            },
            selectSoftwareFinal(id){
                this.noKnows = false;
                this.lstSofwareSelecionado = this.lstSofwareSelecionado.map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            ativo: !item?.ativo
                        }
                    }

                    return item
                });
            },
            selectNaoConheco(){
                this.noKnows = !this.noKnows;
                this.lstSoftwares = this.lstSoftwares.map(item => {
                    return {
                        ...item,
                        ativo: false
                    }
                });
                this.checkHabilitacao();
            },
            next: function() {
                this.loadingBtn = true;
                if (this.page == 1) {
                    this.checkHabilitacao();
                    this.titulo = this.tokens.entreSoftwares;
                    this.subtitulo = this.tokens.sabeMexer;
                    this.page = this.page + 1;
                    this.loadingBtn = false;

                    this.lstSofwareSelecionado = this.lstSofwareSelecionado.map(item => {
                        return {
                            ...item,
                            ativo: false
                        }
                    }); 
                    this.showsSelected = true;
                } else {
                    this.storeDiagnostico();
                }
            },
            prev: function() {
                if (this.page == 2) {
                    this.titulo = this.tokens.startDiagnostico;
                    this.subtitulo = this.tokens.ouviuFalar;
                    this.page = this.page - 1;
                    this.lstSoftwares = this.lstSoftwareTemp;
                } else {
                    alert('Vai pra tela de analise');
                }
            },
        }

    }
</script>