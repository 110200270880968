<template>
    <svg width="184" height="184" viewBox="0 0 184 184" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M174.831 124.468C156.367 167.634 107.623 186.171 69.4271 183.009C58.9964 182.147 38.0174 180.41 21.6366 164.917C-9.82536 135.167 -4.97903 72.9742 25.1374 36.3655C29.0171 31.6498 55.8613 0.0572349 97.6952 0.481778C128.328 0.788756 149.829 18.0905 152.442 20.2459C155.74 22.9695 163.029 29.4422 169.593 39.7031C185.014 63.8237 186.372 97.4997 174.831 124.468Z" fill="url(#paint0_linear_121_3819)"/>
        <path d="M130.671 120.007C130.671 120.007 130.076 94.9853 141.598 88.2906C153.119 81.5958 175.032 77.4092 175.855 41.4863C176.332 20.455 176.855 11.2588 177.201 7.24845C177.233 6.88424 177.181 6.51741 177.048 6.17681C176.915 5.8362 176.705 5.53111 176.434 5.28551C176.163 5.03991 175.839 4.8605 175.487 4.76137C175.135 4.66225 174.765 4.64612 174.405 4.71425C163.491 6.76513 128.039 15.2299 120.606 39.4289C111.795 68.1149 130.671 120.007 130.671 120.007Z" fill="#91B3FA"/>
        <path d="M165.967 14.7071C153.989 25.3925 140.135 44.3011 132.748 63.2096C131.801 66.3251 129.032 72.9545 127.824 84.7241C126.753 94.5474 127.288 104.926 128.588 111.797C128.629 112.005 128.751 112.189 128.927 112.308C129.104 112.427 129.32 112.471 129.528 112.43C130.273 112.287 130.175 111.483 130.169 111.51C128.679 99.7726 129.293 85.1683 132.709 71.9878C137.066 55.8029 146.791 40.1144 156.843 26.8033L157.881 25.497C165.673 15.4909 167.313 15.4125 166.581 14.7202C166.5 14.6393 166.391 14.5929 166.277 14.5904C166.163 14.588 166.052 14.6298 165.967 14.7071Z" fill="#BDD0FB"/>
        <path d="M128.988 131.339C128.099 130.751 130.176 125.461 130.353 124.697C131.014 121.858 131.572 118.999 132.025 116.121C134.435 100.935 134.467 81.9028 143.115 74.5746C150.319 68.4677 174.819 72.3212 182.833 73.7843C183.058 73.8245 183.266 73.9279 183.435 74.0825C183.603 74.2371 183.723 74.4365 183.782 74.6572C183.841 74.8779 183.836 75.1109 183.767 75.3288C183.699 75.5466 183.569 75.7404 183.394 75.8874C179.547 79.1204 171.324 86.7361 169.182 94.4105C166.36 104.528 156.74 110.961 148.131 112.705C142.691 113.802 137.968 115.396 134.729 120.177C132.998 122.737 132.371 128.831 130.444 130.614C129.68 131.313 129.229 131.496 128.988 131.339Z" fill="#BDD0FB"/>
        <path d="M171.559 78.062C161.331 78.4865 150.352 83.1565 144.78 91.9086L144.251 92.7446C143.043 94.7041 136.159 108.623 132.331 120.164C132.31 120.233 132.317 120.308 132.35 120.372C132.383 120.437 132.439 120.486 132.508 120.51C132.749 120.588 132.873 120.32 132.854 120.347C135.512 113.828 139.34 105.559 142.39 99.3806C145.655 92.6336 147.941 88.0681 155.394 83.6921C163.689 78.9829 171.618 78.493 171.592 78.4996C171.65 78.493 171.702 78.464 171.739 78.4188C171.776 78.3736 171.793 78.3158 171.788 78.2579C171.783 78.2019 171.756 78.15 171.713 78.1134C171.671 78.0768 171.615 78.0583 171.559 78.062Z" fill="url(#paint1_linear_121_3819)"/>
        <path d="M76.8023 95.8208C76.8023 95.8208 71.0546 73.8229 59.3177 70.8577C47.5807 67.8924 27.392 69.7277 17.6797 38.5597C11.9909 20.3108 9.23459 12.4143 7.9283 8.99839C7.80882 8.6888 7.76266 8.35574 7.79345 8.02532C7.82424 7.6949 7.93113 7.37611 8.10576 7.09392C8.28039 6.81173 8.518 6.57383 8.79997 6.39885C9.08193 6.22387 9.4006 6.11657 9.73097 6.08537C19.7763 5.14484 52.8581 3.65567 65.4115 22.9299C80.2901 45.7769 76.8023 95.8208 76.8023 95.8208Z" fill="#91B3FA"/>
        <path d="M19.6038 12.702C32.745 19.031 49.5766 32.0743 60.7584 46.7374C62.3651 49.2258 66.4473 54.3138 70.4445 64.3004C73.8474 72.6084 75.9766 81.8046 76.5579 88.1271C76.5669 88.2224 76.5569 88.3185 76.5286 88.4099C76.5002 88.5013 76.454 88.5862 76.3927 88.6596C76.3314 88.7331 76.2561 88.7937 76.1713 88.8379C76.0864 88.8822 75.9936 88.9092 75.8983 88.9174C75.2125 88.9827 75.1014 88.2577 75.1145 88.2773C73.4751 77.6507 69.2754 65.0515 62.9921 54.3922C55.1348 41.3554 42.712 30.0822 30.6027 20.9774L29.3683 20.0956C20.061 13.3095 18.611 13.6556 19.0682 12.8653C19.1204 12.7755 19.2046 12.7088 19.304 12.6785C19.4033 12.6482 19.5104 12.6566 19.6038 12.702Z" fill="#BDD0FB"/>
        <path d="M106.441 132.012C107.995 132.293 88.6097 118.459 76.0693 91.5886C70.1649 78.9307 65.3708 62.3212 55.9786 58.0823C48.167 54.5553 27.7301 64.0586 21.0942 67.3439C20.9093 67.437 20.7545 67.5804 20.6477 67.7577C20.5409 67.935 20.4864 68.1388 20.4904 68.3458C20.4945 68.5527 20.5571 68.7543 20.6709 68.9272C20.7846 69.1001 20.945 69.2373 21.1334 69.3229C25.3004 71.1779 34.3922 75.7695 38.187 81.9351C43.1835 90.0602 53.1962 93.2737 61.1515 92.6402C69.1068 92.0066 73.7115 92.2809 80.8242 103.254C87.9369 114.227 102.032 131.228 106.441 132.012Z" fill="#BDD0FB"/>
        <path d="M32.0151 68.2518C41.0546 66.0638 51.8118 67.3897 58.8658 73.6403L59.5385 74.2347C61.0865 75.6454 70.5832 86.0761 76.8142 95.194C76.8532 95.2477 76.8692 95.3147 76.8588 95.3802C76.8484 95.4458 76.8124 95.5045 76.7587 95.5435C76.705 95.5824 76.638 95.5985 76.5725 95.5881C76.5069 95.5777 76.4482 95.5416 76.4092 95.4879C72.4512 90.4587 67.0366 84.2016 62.8304 79.5643C58.2845 74.4959 55.1494 71.08 47.5403 69.1205C39.1147 67.0827 32.0738 68.6437 32.0934 68.6437C32.0454 68.6454 31.9984 68.63 31.9607 68.6002C31.923 68.5704 31.8971 68.5282 31.8877 68.4811C31.8783 68.434 31.8859 68.3851 31.9093 68.3431C31.9326 68.3011 31.9701 68.2687 32.0151 68.2518Z" fill="url(#paint2_linear_121_3819)"/>
        <path d="M119.98 44.5884C119.98 44.5884 127.635 62.7523 146.851 64.7901L146.341 68.1342C146.341 68.1342 121.241 72.4907 107.976 50.4471L119.98 44.5884Z" fill="url(#paint3_linear_121_3819)"/>
        <path d="M77.0868 90.0802C77.0868 90.0802 69.0858 58.1676 86.2439 31.2385C86.4355 30.9354 86.7037 30.6882 87.0213 30.522C87.339 30.3557 87.6949 30.2761 88.0531 30.2914C92.0961 30.4939 105.975 32.192 121.579 45.784C121.579 45.784 105.179 59.6894 103.206 90.7595C103.206 90.7595 88.0009 91.4126 77.0868 90.0802Z" fill="url(#paint4_linear_121_3819)"/>
        <path d="M109.529 24.5107L101.411 33.4849C101.042 33.8823 100.84 34.406 100.846 34.948C100.852 35.49 101.066 36.009 101.443 36.398C102.795 37.8414 105.199 39.7029 108.334 39.3436C108.83 39.2898 109.307 39.1228 109.728 38.8556C110.149 38.5883 110.504 38.228 110.764 37.8022L115.682 29.5073L109.529 24.5107Z" fill="url(#paint5_linear_121_3819)"/>
        <path d="M123.246 18.7494C123.246 18.7494 122.423 33.8305 115.8 34.6273C109.178 35.4242 108.818 20.6109 108.818 20.6109C108.818 20.6109 115.173 7.3194 123.246 18.7494Z" fill="url(#paint6_linear_121_3819)"/>
        <path d="M111.692 25.2615C111.692 25.2615 111.927 20.598 109.262 21.5059C105.84 22.6685 110.15 26.3588 110.15 26.3588L111.692 25.2615Z" fill="url(#paint7_linear_121_3819)"/>
        <path d="M111.105 22.1137C111.105 22.1137 110.073 25.2162 111.105 24.5108C112.137 23.8054 112.954 16.1897 117.545 18.528C122.137 20.8662 130.726 22.2705 128.838 13.4139C126.951 4.55724 121.627 12.1925 114.057 10.1416C106.488 8.09075 107.487 15.2753 108.264 16.5555C106.037 17.6462 106.2 20.4025 108.264 22.1072C108.878 21.6631 110.073 20.5527 111.105 22.1137Z" fill="url(#paint8_linear_121_3819)"/>
        <path d="M125.074 19.6389C125.052 19.6391 125.031 19.6313 125.014 19.6169C124.997 19.6025 124.986 19.5824 124.983 19.5605C124.979 19.5485 124.978 19.5357 124.979 19.5231C124.981 19.5105 124.985 19.4984 124.992 19.4878C124.999 19.4771 125.008 19.4682 125.019 19.4615C125.03 19.4549 125.042 19.4508 125.055 19.4495C125.074 19.4429 126.622 19.1425 127.504 17.7121C128.242 16.5038 128.32 14.8644 127.726 12.8331C127.722 12.8211 127.721 12.8084 127.722 12.7958C127.723 12.7833 127.727 12.7711 127.733 12.76C127.739 12.749 127.747 12.7393 127.757 12.7317C127.767 12.724 127.779 12.7185 127.791 12.7156C127.803 12.7116 127.816 12.7102 127.829 12.7113C127.841 12.7125 127.853 12.7163 127.864 12.7224C127.875 12.7286 127.885 12.7369 127.893 12.747C127.9 12.7571 127.906 12.7686 127.909 12.7809C128.523 14.8644 128.438 16.5626 127.667 17.8166C126.733 19.3188 125.159 19.6258 125.094 19.6389H125.074Z" fill="#91B3FA"/>
        <path d="M85.6185 30.84C85.6185 30.84 59.1923 56.6065 65.7499 64.1568C72.3074 71.7072 94.1486 80.9688 94.1486 80.9688L93.4497 75.9069C93.4497 75.9069 74.7763 61.7467 77.1799 59.5065C78.4274 58.3439 82.3528 54.5556 85.821 51.1985C90.3734 46.7898 92.052 40.1277 89.9489 34.1449C89.0214 31.4997 87.6498 29.7296 85.6185 30.84Z" fill="url(#paint9_linear_121_3819)"/>
        <path d="M173.442 170.031C120.328 141.11 64.6344 143.174 6.57647 172.226C4.15984 173.428 1.3252 171.671 1.3252 168.973V123.194C1.32555 121.413 1.78639 119.662 2.66301 118.112C3.53962 116.561 4.80223 115.263 6.32828 114.344C64.2556 79.5318 121.438 79.4012 175.995 114.619C177.582 115.646 178.89 117.049 179.804 118.703C180.718 120.358 181.209 122.212 181.234 124.102L181.776 165.009C181.828 169.319 177.223 172.095 173.442 170.031Z" fill="url(#paint10_linear_121_3819)"/>
        <path d="M172.631 168.966C120.464 141.208 65.7568 143.187 8.71776 171.063C6.35338 172.225 3.56445 170.534 3.56445 167.947V124.01C3.56445 120.536 5.43245 117.309 8.48263 115.519C65.378 82.1111 121.548 81.9804 175.145 115.781C178.313 117.779 180.24 121.202 180.286 124.885L180.821 164.139C180.873 168.28 176.354 170.945 172.631 168.966Z" fill="url(#paint11_linear_121_3819)"/>
        <path d="M108.603 113.958L102.763 119.889C102.539 120.115 102.373 120.393 102.281 120.698C102.188 121.003 102.172 121.327 102.234 121.639L103.802 129.81C104.109 131.41 102.45 132.671 100.993 131.94L93.5476 128.217C93.2624 128.074 92.947 128.004 92.6284 128.011C92.3098 128.017 91.9976 128.102 91.7188 128.256L84.4297 132.273C83.0059 133.063 81.2946 131.868 81.5363 130.254L82.7773 122.025C82.8263 121.71 82.7972 121.388 82.6924 121.088C82.5876 120.787 82.4104 120.516 82.1764 120.3L76.1021 114.612C74.9134 113.495 75.5208 111.503 77.1276 111.235L85.3376 109.876C85.6525 109.822 85.9497 109.692 86.2042 109.499C86.4588 109.306 86.6633 109.055 86.8006 108.766L90.3342 101.235C91.0265 99.7591 93.11 99.7134 93.8546 101.163L97.6886 108.55C97.8355 108.833 98.049 109.076 98.3107 109.258C98.5724 109.44 98.8744 109.556 99.1908 109.595L107.447 110.627C109.066 110.83 109.752 112.802 108.603 113.958Z" :fill="star3" id="star3"/>
        <path opacity="0.62" d="M92.0185 126.16C91.5912 126.238 91.179 126.384 90.7971 126.591L86.0422 129.217C85.7986 129.352 85.5221 129.416 85.2439 129.402C84.9657 129.388 84.697 129.296 84.4684 129.137C84.2399 128.977 84.0605 128.757 83.9508 128.501C83.841 128.245 83.8053 127.964 83.8476 127.688L84.6575 122.313C84.7517 121.693 84.6939 121.059 84.4891 120.467C84.2843 119.874 83.9387 119.34 83.4819 118.91L79.5107 115.194C79.3086 115.003 79.1636 114.76 79.0921 114.492C79.0207 114.224 79.0257 113.941 79.1065 113.675C79.1873 113.41 79.3408 113.172 79.5496 112.989C79.7583 112.806 80.0141 112.685 80.288 112.64L83.0247 112.183L83.0965 112.601L80.3533 113.058C80.1569 113.09 79.9734 113.176 79.8237 113.307C79.6739 113.438 79.5639 113.609 79.5061 113.799C79.4483 113.99 79.445 114.193 79.4966 114.385C79.5482 114.577 79.6527 114.751 79.7981 114.887L83.7693 118.603C84.2777 119.079 84.6625 119.671 84.8901 120.329C85.1177 120.987 85.1814 121.69 85.0755 122.378L84.2656 127.747C84.2345 127.944 84.2594 128.146 84.3374 128.329C84.4154 128.513 84.5433 128.671 84.7067 128.785C84.8701 128.9 85.0623 128.966 85.2615 128.976C85.4606 128.987 85.6587 128.941 85.8332 128.844L90.5946 126.219C91.0152 125.986 91.4724 125.827 91.9466 125.748L92.0185 126.16Z" fill="white"/>
        <path opacity="0.62" d="M101.322 128.158C101.22 128.416 101.048 128.64 100.825 128.805C100.605 128.974 100.342 129.077 100.066 129.102C99.7894 129.127 99.5116 129.074 99.2643 128.948L95.5414 127.087L95.7308 126.708L99.4537 128.57C99.6298 128.662 99.8286 128.702 100.027 128.684C100.225 128.667 100.413 128.593 100.571 128.472C100.732 128.352 100.856 128.189 100.928 128.002C101 127.814 101.016 127.61 100.976 127.413L99.9501 122.077C99.8227 121.394 99.8612 120.691 100.062 120.026C100.264 119.361 100.622 118.754 101.106 118.256L104.927 114.383C105.067 114.242 105.164 114.064 105.208 113.87C105.252 113.676 105.241 113.474 105.175 113.286C105.112 113.097 104.997 112.93 104.841 112.805C104.686 112.68 104.498 112.602 104.3 112.581L98.9051 111.901C98.2147 111.816 97.5556 111.563 96.9844 111.166C96.4132 110.769 95.9471 110.239 95.6263 109.622L93.1248 104.795C93.0346 104.618 92.8959 104.469 92.7247 104.368C92.5536 104.266 92.3571 104.215 92.1581 104.22C91.9579 104.22 91.7619 104.278 91.5939 104.387C91.4259 104.496 91.2929 104.652 91.2111 104.834L88.9055 109.759C88.6626 110.263 88.3282 110.717 87.9192 111.098L87.6318 110.784C88.0026 110.442 88.3041 110.032 88.5201 109.576L90.8257 104.651C90.9437 104.4 91.1298 104.186 91.363 104.034C91.5962 103.883 91.8671 103.8 92.1451 103.794C92.4232 103.789 92.6972 103.861 92.9361 104.004C93.175 104.146 93.3694 104.352 93.4971 104.599L96.0051 109.426C96.2922 109.984 96.7113 110.462 97.2259 110.821C97.7404 111.18 98.3348 111.407 98.9574 111.483L104.352 112.156C104.628 112.192 104.887 112.304 105.102 112.48C105.318 112.655 105.48 112.887 105.571 113.15C105.661 113.412 105.677 113.694 105.617 113.965C105.556 114.236 105.421 114.485 105.228 114.684L101.413 118.55C100.974 118.998 100.65 119.546 100.468 120.146C100.286 120.747 100.252 121.382 100.368 121.999L101.394 127.335C101.446 127.611 101.421 127.896 101.322 128.158Z" fill="white"/>
        <path opacity="0.62" d="M84.6453 111.918L83.0215 112.187L83.0905 112.604L84.7143 112.335L84.6453 111.918Z" fill="white"/>
        <path d="M72.7382 115.33L68.7606 122.639C68.6084 122.919 68.5264 123.231 68.5219 123.55C68.5173 123.869 68.5903 124.184 68.7345 124.468L72.5031 131.887C73.2412 133.344 71.9936 135.009 70.3934 134.715L62.2096 133.194C61.8962 133.133 61.5727 133.149 61.2674 133.243C60.9622 133.336 60.6846 133.503 60.4591 133.729L54.5678 139.608C53.4117 140.757 51.4392 140.084 51.2302 138.471L50.146 130.222C50.1066 129.905 49.9901 129.604 49.8068 129.343C49.6234 129.082 49.3788 128.87 49.0944 128.726L41.6812 124.931C40.2313 124.193 40.2639 122.11 41.7335 121.411L49.2512 117.832C49.5385 117.694 49.788 117.488 49.978 117.232C50.168 116.976 50.2929 116.677 50.3419 116.362L51.6547 108.146C51.916 106.539 53.9081 105.918 55.025 107.107L60.753 113.149C60.9724 113.38 61.2445 113.554 61.546 113.656C61.8475 113.758 62.1693 113.786 62.4839 113.737L70.707 112.443C72.3137 112.195 73.5155 113.893 72.7382 115.33Z" :fill="star2" id="star2"/>
        <path opacity="0.62" d="M60.1707 131.64C59.7808 131.827 59.4253 132.079 59.1192 132.385L55.2656 136.225C55.0688 136.421 54.821 136.558 54.5503 136.621C54.2796 136.684 53.9968 136.669 53.7338 136.58C53.4709 136.49 53.2382 136.328 53.0623 136.113C52.8863 135.898 52.7739 135.638 52.738 135.363L52.0326 129.975C51.9521 129.352 51.7209 128.758 51.359 128.244C50.997 127.731 50.5153 127.314 49.9556 127.029L45.1158 124.553C44.8683 124.427 44.6612 124.235 44.518 123.997C44.3749 123.759 44.3013 123.486 44.3059 123.208C44.3101 122.93 44.3918 122.658 44.5418 122.424C44.6918 122.19 44.9041 122.002 45.1549 121.882L47.663 120.693L47.8459 121.072L45.3378 122.267C45.1573 122.352 45.0043 122.486 44.8963 122.654C44.7882 122.822 44.7295 123.017 44.7268 123.216C44.724 123.416 44.7774 123.612 44.8809 123.783C44.9843 123.953 45.1336 124.091 45.3117 124.181L50.1515 126.657C50.7703 126.973 51.3029 127.435 51.7034 128.002C52.1039 128.57 52.3603 129.227 52.4506 129.916L53.156 135.304C53.1821 135.502 53.263 135.688 53.3894 135.843C53.5158 135.997 53.6828 136.113 53.8715 136.177C54.0602 136.242 54.2632 136.252 54.4576 136.208C54.652 136.163 54.83 136.065 54.9717 135.925L58.8187 132.084C59.1596 131.745 59.5547 131.464 59.9879 131.255L60.1707 131.64Z" fill="white"/>
        <path opacity="0.62" d="M69.6574 130.986C69.6359 131.261 69.5362 131.524 69.37 131.744C69.2035 131.968 68.9774 132.14 68.7176 132.242C68.4578 132.344 68.1746 132.37 67.9004 132.319L63.8117 131.555L63.8901 131.137L67.9788 131.901C68.1748 131.937 68.3768 131.917 68.5623 131.844C68.7478 131.772 68.9093 131.649 69.0287 131.489C69.1481 131.33 69.2207 131.14 69.2383 130.942C69.2559 130.743 69.2178 130.544 69.1283 130.366L66.666 125.52C66.3534 124.899 66.1955 124.212 66.2057 123.517C66.2159 122.822 66.394 122.14 66.7248 121.529L69.3177 116.754C69.4167 116.581 69.4633 116.382 69.4517 116.183C69.4401 115.984 69.3709 115.792 69.2524 115.631C69.139 115.466 68.9812 115.337 68.7974 115.259C68.6136 115.18 68.4113 115.155 68.2139 115.187L62.8451 116.036C62.1581 116.143 61.4555 116.081 60.7978 115.856C60.14 115.63 59.547 115.248 59.0699 114.743L55.3274 110.798C55.1914 110.652 55.0164 110.547 54.8231 110.498C54.6298 110.448 54.4263 110.455 54.2366 110.517C54.0447 110.572 53.8727 110.681 53.7417 110.832C53.6107 110.983 53.5263 111.168 53.4986 111.366L52.643 116.735C52.555 117.286 52.3598 117.814 52.0682 118.289L51.709 118.074C51.9705 117.641 52.1457 117.162 52.225 116.663L53.0806 111.301C53.124 111.026 53.2435 110.768 53.4257 110.558C53.6079 110.347 53.8454 110.192 54.1113 110.11C54.3772 110.028 54.6609 110.022 54.9301 110.092C55.1993 110.163 55.4433 110.308 55.6344 110.51L59.3704 114.455C59.803 114.909 60.3389 115.252 60.9325 115.455C61.5261 115.657 62.1599 115.713 62.7798 115.618L68.1486 114.775C68.423 114.73 68.7047 114.762 68.9612 114.87C69.2177 114.977 69.4387 115.155 69.5986 115.383C69.7596 115.61 69.8536 115.878 69.8697 116.157C69.8859 116.435 69.8236 116.712 69.69 116.957L67.0905 121.731C66.7938 122.283 66.6347 122.899 66.6268 123.525C66.6188 124.152 66.7622 124.771 67.0448 125.33L69.5006 130.176C69.6291 130.426 69.6835 130.707 69.6574 130.986Z" fill="white"/>
        <path opacity="0.62" d="M49.0649 120.099L47.5781 120.805L47.7594 121.186L49.2462 120.48L49.0649 120.099Z" fill="white"/>
        <path d="M37.3254 126.192L33.3478 133.501C33.1939 133.78 33.1111 134.093 33.1065 134.412C33.102 134.731 33.1758 135.046 33.3216 135.33L37.0903 142.749C37.8283 144.206 36.5743 145.871 34.9741 145.578L26.7902 144.049C26.4774 143.991 26.1551 144.01 25.8512 144.105C25.5474 144.199 25.2711 144.366 25.0463 144.591L19.1484 150.47C17.9989 151.619 16.0264 150.946 15.8109 149.333L14.7332 141.077C14.692 140.761 14.5741 140.459 14.3897 140.198C14.2053 139.938 13.96 139.726 13.6751 139.582L6.26843 135.793C4.81845 135.055 4.84457 132.972 6.31415 132.267L13.8318 128.694C14.1201 128.556 14.3705 128.35 14.5617 128.094C14.7528 127.838 14.8789 127.54 14.9291 127.224L16.2419 119.008C16.4967 117.401 18.4887 116.78 19.6122 117.963L25.3337 124.004C25.554 124.236 25.8271 124.411 26.1295 124.514C26.432 124.618 26.755 124.647 27.0711 124.599L35.2876 123.305C36.9009 123.057 38.1027 124.755 37.3254 126.192Z" :fill="star1" id="star1"/>
        <path opacity="0.62" d="M24.759 142.502C24.3658 142.686 24.0077 142.938 23.7009 143.246L19.8539 147.087C19.6562 147.282 19.4078 147.419 19.1366 147.481C18.8655 147.544 18.5823 147.529 18.319 147.439C18.0557 147.351 17.8226 147.19 17.6469 146.975C17.4712 146.76 17.36 146.5 17.3262 146.224L16.6208 140.836C16.5393 140.213 16.3077 139.62 15.9459 139.107C15.584 138.594 15.1029 138.176 14.5438 137.89L9.70405 135.415C9.45647 135.288 9.24926 135.095 9.10588 134.857C8.96249 134.619 8.88866 134.345 8.89274 134.067C8.89682 133.789 8.97864 133.518 9.12895 133.284C9.27926 133.05 9.49205 132.863 9.74323 132.744L12.2513 131.548L12.4342 131.934L9.92611 133.129C9.74482 133.213 9.59096 133.346 9.48232 133.514C9.37367 133.681 9.31468 133.876 9.31216 134.076C9.30955 134.275 9.36257 134.471 9.46526 134.642C9.56795 134.813 9.71624 134.952 9.89345 135.043L14.7333 137.518C15.3536 137.833 15.8878 138.294 16.2895 138.862C16.6913 139.43 16.9485 140.087 17.0388 140.777L17.7442 146.166C17.769 146.363 17.8488 146.55 17.9745 146.705C18.1002 146.86 18.2667 146.976 18.4552 147.04C18.6437 147.105 18.8466 147.116 19.0408 147.071C19.2349 147.026 19.4125 146.927 19.5535 146.786L23.4005 142.946C23.7437 142.606 24.141 142.326 24.5761 142.116L24.759 142.502Z" fill="white"/>
        <path opacity="0.62" d="M34.244 141.848C34.2168 142.123 34.1152 142.385 33.9501 142.606C33.7848 142.829 33.5597 143.001 33.3008 143.102C33.0418 143.202 32.7597 143.227 32.487 143.174L28.3983 142.416L28.4767 141.998L32.5654 142.763C32.7609 142.797 32.9621 142.776 33.1465 142.703C33.3309 142.63 33.4913 142.506 33.6099 142.347C33.7284 142.188 33.8004 141.999 33.8178 141.801C33.8352 141.604 33.7973 141.405 33.7084 141.228L31.2461 136.381C30.9366 135.76 30.7805 135.073 30.7907 134.379C30.801 133.685 30.9772 133.003 31.3048 132.391L33.9044 127.616C33.9989 127.441 34.0427 127.243 34.0309 127.044C34.0191 126.845 33.9521 126.654 33.8374 126.491C33.7228 126.328 33.565 126.2 33.3819 126.122C33.1987 126.044 32.9974 126.019 32.8005 126.049L27.4317 126.891C26.7442 127 26.0406 126.94 25.3817 126.715C24.7228 126.491 24.1284 126.11 23.65 125.604L19.914 121.659C19.7774 121.514 19.6027 121.41 19.4098 121.359C19.217 121.309 19.0137 121.313 18.8233 121.372C18.6316 121.43 18.4603 121.541 18.3295 121.692C18.1988 121.844 18.114 122.03 18.0852 122.228L17.2231 127.597C17.1382 128.148 16.9427 128.677 16.6483 129.151L16.2891 128.935C16.5506 128.503 16.7258 128.024 16.805 127.525L17.6672 122.162C17.7115 121.889 17.8312 121.633 18.013 121.424C18.1947 121.215 18.4313 121.06 18.696 120.978C18.9607 120.896 19.243 120.889 19.5113 120.959C19.7796 121.029 20.0231 121.172 20.2145 121.372L23.957 125.317C24.3886 125.772 24.9246 126.115 25.5187 126.317C26.1127 126.518 26.7469 126.572 27.3664 126.473L32.7352 125.631C33.0106 125.586 33.293 125.62 33.5501 125.728C33.8071 125.837 34.0286 126.015 34.1893 126.243C34.35 126.471 34.4433 126.74 34.4588 127.018C34.4742 127.297 34.4111 127.574 34.2766 127.819L31.6771 132.593C31.381 133.145 31.2217 133.76 31.2126 134.386C31.2035 135.012 31.3449 135.632 31.6249 136.192L34.0872 141.038C34.2157 141.288 34.2701 141.569 34.244 141.848Z" fill="white"/>
        <path opacity="0.62" d="M13.6226 130.936L12.1357 131.642L12.317 132.023L13.8038 131.317L13.6226 130.936Z" fill="white"/>
        <path d="M178.038 138.021L170.272 141.012C169.973 141.126 169.707 141.311 169.497 141.551C169.286 141.792 169.137 142.08 169.063 142.39L167.124 150.483C166.745 152.07 164.714 152.527 163.688 151.26L158.443 144.801C158.241 144.554 157.982 144.359 157.689 144.233C157.396 144.106 157.077 144.053 156.758 144.076L148.463 144.735C146.837 144.866 145.772 143.07 146.661 141.705L151.187 134.723C151.36 134.455 151.465 134.149 151.493 133.832C151.522 133.514 151.473 133.194 151.35 132.9L148.163 125.213C147.536 123.704 148.914 122.136 150.488 122.554L158.528 124.703C158.836 124.784 159.159 124.79 159.469 124.719C159.78 124.649 160.069 124.504 160.311 124.298L166.64 118.89C167.881 117.832 169.795 118.655 169.88 120.282L170.324 128.59C170.341 128.908 170.435 129.217 170.597 129.491C170.76 129.766 170.987 129.996 171.258 130.164L178.358 134.507C179.749 135.363 179.559 137.433 178.038 138.021Z" :fill="star5" id="star5"/>
        <path d="M144.087 123.208L136.824 127.264C136.546 127.42 136.31 127.641 136.136 127.909C135.962 128.176 135.855 128.481 135.825 128.799L135.048 137.087C134.898 138.707 132.951 139.452 131.756 138.348L125.649 132.692C125.415 132.477 125.132 132.321 124.826 132.238C124.519 132.155 124.196 132.147 123.886 132.215L115.761 134.044C114.173 134.396 112.861 132.777 113.546 131.301L117.034 123.744C117.17 123.455 117.232 123.137 117.216 122.818C117.2 122.499 117.106 122.189 116.943 121.915L112.697 114.756C111.868 113.352 113.004 111.608 114.624 111.798L122.886 112.784C123.203 112.823 123.525 112.784 123.823 112.67C124.122 112.557 124.388 112.371 124.598 112.131L130.097 105.88C131.168 104.659 133.18 105.201 133.5 106.801L135.113 114.965C135.178 115.278 135.317 115.57 135.518 115.818C135.719 116.065 135.977 116.261 136.269 116.389L143.911 119.688C145.407 120.328 145.511 122.411 144.087 123.208Z" :fill="star4" id="star4"/>
        <path opacity="0.62" d="M124.756 130.294C124.327 130.244 123.892 130.266 123.47 130.36L118.166 131.555C117.894 131.616 117.61 131.6 117.347 131.507C117.083 131.415 116.851 131.25 116.677 131.032C116.501 130.816 116.39 130.555 116.357 130.279C116.323 130.003 116.369 129.723 116.487 129.471L118.767 124.54C119.03 123.97 119.15 123.345 119.119 122.718C119.087 122.091 118.903 121.482 118.584 120.941L115.808 116.271C115.666 116.032 115.593 115.758 115.599 115.48C115.604 115.202 115.688 114.931 115.839 114.698C115.991 114.465 116.205 114.279 116.457 114.161C116.709 114.043 116.989 113.998 117.265 114.031L120.027 114.358L119.975 114.776L117.219 114.449C117.02 114.424 116.819 114.456 116.638 114.54C116.456 114.624 116.302 114.758 116.194 114.926C116.083 115.093 116.022 115.288 116.019 115.488C116.016 115.688 116.069 115.885 116.174 116.056L118.943 120.726C119.299 121.324 119.503 122 119.54 122.695C119.576 123.39 119.443 124.084 119.152 124.716L116.866 129.654C116.783 129.835 116.752 130.036 116.777 130.234C116.802 130.431 116.882 130.618 117.008 130.773C117.134 130.927 117.3 131.043 117.489 131.108C117.678 131.172 117.88 131.182 118.075 131.137L123.372 129.948C123.843 129.844 124.328 129.817 124.808 129.87L124.756 130.294Z" fill="white"/>
        <path opacity="0.62" d="M133.129 134.801C133.004 134.966 132.845 135.104 132.663 135.204C132.481 135.305 132.281 135.366 132.074 135.384C131.867 135.402 131.659 135.376 131.462 135.308C131.266 135.24 131.086 135.132 130.934 134.99L127.878 132.162L128.165 131.855L131.222 134.677C131.365 134.816 131.546 134.91 131.742 134.948C131.938 134.986 132.14 134.967 132.326 134.892C132.512 134.823 132.674 134.702 132.795 134.543C132.915 134.385 132.988 134.196 133.005 133.997L133.514 128.583C133.579 127.891 133.811 127.226 134.19 126.643C134.569 126.061 135.083 125.579 135.689 125.239L140.438 122.587C140.612 122.49 140.755 122.346 140.851 122.172C140.947 121.998 140.991 121.8 140.98 121.601C140.973 121.401 140.909 121.208 140.795 121.043C140.682 120.879 140.524 120.751 140.34 120.673L135.343 118.518C134.705 118.243 134.143 117.817 133.706 117.276C133.269 116.736 132.97 116.097 132.835 115.416L131.783 110.086C131.746 109.889 131.655 109.707 131.519 109.561C131.384 109.414 131.21 109.308 131.017 109.256C130.824 109.203 130.62 109.206 130.429 109.264C130.238 109.322 130.066 109.432 129.935 109.583L126.343 113.665C125.974 114.086 125.526 114.43 125.023 114.677L124.834 114.299C125.287 114.078 125.691 113.77 126.023 113.391L129.615 109.309C129.798 109.1 130.036 108.946 130.303 108.866C130.569 108.785 130.852 108.781 131.12 108.853C131.389 108.925 131.632 109.07 131.822 109.273C132.011 109.476 132.141 109.728 132.195 110.001L133.253 115.331C133.374 115.946 133.643 116.523 134.036 117.011C134.43 117.499 134.937 117.884 135.513 118.133L140.503 120.288C140.759 120.396 140.98 120.575 141.14 120.803C141.3 121.031 141.392 121.299 141.406 121.577C141.421 121.856 141.356 122.132 141.221 122.375C141.085 122.618 140.884 122.819 140.64 122.953L135.898 125.605C135.352 125.912 134.888 126.347 134.546 126.873C134.204 127.398 133.993 127.998 133.932 128.622L133.429 134.037C133.403 134.315 133.299 134.579 133.129 134.801Z" fill="white"/>
        <path opacity="0.62" d="M120.002 114.335L119.952 114.757L121.586 114.952L121.637 114.53L120.002 114.335Z" fill="white"/>
        <path d="M149.541 74.9335L135.27 81.9614C134.724 82.2305 134.251 82.6286 133.892 83.1213C133.534 83.614 133.301 84.1865 133.213 84.7895L130.914 100.537C130.463 103.62 126.668 104.848 124.5 102.614L113.403 91.2099C112.977 90.7725 112.452 90.4453 111.871 90.2564C111.291 90.0675 110.673 90.0226 110.072 90.1257L94.3899 92.8035C91.3201 93.3261 88.9819 90.1061 90.4318 87.3498L97.845 73.268C98.1292 72.7297 98.2783 72.1304 98.2794 71.5217C98.2806 70.9129 98.1337 70.3131 97.8515 69.7737L90.458 55.6854C89.008 52.9226 91.3528 49.7026 94.4225 50.2316L110.105 52.9356C110.705 53.0378 111.32 52.9931 111.899 52.8055C112.478 52.6178 113.003 52.2927 113.429 51.858L124.546 40.4671C126.72 38.2399 130.509 39.4744 130.953 42.5572L133.232 58.3045C133.319 58.9078 133.551 59.4809 133.908 59.9747C134.265 60.4685 134.737 60.8681 135.283 61.1391L149.548 68.1866C152.343 69.5712 152.337 73.5554 149.541 74.9335Z" fill="url(#paint13_linear_121_3819)"/>
        <path opacity="0.62" d="M111.934 86.54C111.117 86.4018 110.282 86.404 109.466 86.5465L99.2177 88.2904C98.694 88.3797 98.1557 88.3206 97.6637 88.12C97.1718 87.9193 96.7458 87.585 96.4339 87.1549C96.122 86.7248 95.9367 86.216 95.8988 85.686C95.861 85.1561 95.9722 84.6261 96.2198 84.156L101.066 74.9663C101.624 73.9049 101.916 72.7236 101.916 71.5242C101.916 70.3249 101.624 69.1436 101.066 68.0822L96.2394 58.8794C95.993 58.409 95.8831 57.879 95.922 57.3494C95.9609 56.8198 96.1471 56.3117 96.4596 55.8823C96.7721 55.453 97.1984 55.1196 97.6904 54.9198C98.1824 54.7199 98.7205 54.6617 99.2438 54.7515L104.476 55.6528L104.338 56.4497L99.1067 55.5483C98.7316 55.4836 98.3459 55.5253 97.9932 55.6686C97.6406 55.8119 97.3352 56.051 97.1115 56.359C96.8878 56.667 96.7549 57.0314 96.7277 57.4111C96.7006 57.7907 96.7803 58.1704 96.9578 58.5071L101.785 67.7099C102.403 68.8864 102.726 70.1959 102.725 71.525C102.723 72.8542 102.399 74.1631 101.778 75.3386L96.9382 84.5349C96.7607 84.8715 96.681 85.2512 96.7081 85.6309C96.7353 86.0105 96.8682 86.375 97.0919 86.6829C97.3156 86.9909 97.621 87.2301 97.9737 87.3734C98.3263 87.5166 98.712 87.5583 99.0871 87.4936L109.328 85.7497C110.236 85.5941 111.163 85.5919 112.072 85.7432L111.934 86.54Z" fill="white"/>
        <path opacity="0.62" d="M127.465 95.9847C127.208 96.2861 126.89 96.5304 126.532 96.7021C126.175 96.8739 125.786 96.9692 125.389 96.9821C124.993 96.995 124.598 96.9252 124.23 96.7771C123.862 96.629 123.529 96.4058 123.253 96.1218L117.707 90.4264L118.289 89.8647L123.834 95.5601C124.1 95.8316 124.437 96.0233 124.806 96.1139C125.175 96.2045 125.563 96.1903 125.924 96.0729C126.286 95.9555 126.608 95.7396 126.853 95.4494C127.099 95.1592 127.259 94.8063 127.315 94.4302L128.817 84.1497C129.013 82.8358 129.524 81.5886 130.305 80.5141C131.086 79.4395 132.115 78.5694 133.304 77.9775L142.625 73.3859C142.968 73.2197 143.258 72.9599 143.46 72.6363C143.662 72.3128 143.769 71.9387 143.768 71.5571C143.773 71.1756 143.668 70.8006 143.467 70.4766C143.265 70.1525 142.976 69.8929 142.631 69.7283L133.317 65.1171C132.125 64.5283 131.094 63.6581 130.313 62.5815C129.533 61.505 129.026 60.2546 128.837 58.9384L127.348 48.6579C127.293 48.2813 127.133 47.9275 126.888 47.6365C126.643 47.3454 126.321 47.1287 125.959 47.0105C125.598 46.8924 125.21 46.8777 124.84 46.9679C124.47 47.0582 124.133 47.2499 123.866 47.5214L116.61 54.9607C115.864 55.7261 114.974 56.3366 113.991 56.7569L113.671 56.0188C114.557 55.6387 115.359 55.0859 116.029 54.3925L123.292 46.9597C123.663 46.5805 124.133 46.3125 124.648 46.186C125.164 46.0596 125.704 46.0797 126.209 46.244C126.713 46.4084 127.162 46.7104 127.504 47.1162C127.846 47.5219 128.068 48.0152 128.145 48.5403L129.634 58.8273C129.805 60.0144 130.262 61.142 130.967 62.1127C131.671 63.0834 132.601 63.868 133.677 64.3986L142.991 69.0033C143.467 69.239 143.868 69.6034 144.148 70.055C144.428 70.5067 144.576 71.0277 144.575 71.5591C144.574 72.0906 144.425 72.6112 144.144 73.0621C143.863 73.5131 143.461 73.8764 142.984 74.1109L133.664 78.7025C132.591 79.2362 131.663 80.0207 130.958 80.9894C130.253 81.9581 129.792 83.0825 129.614 84.2673L128.112 94.5478C128.036 95.0781 127.812 95.5763 127.465 95.9847Z" fill="white"/>
        <path opacity="0.62" d="M104.513 55.6824L104.375 56.4805L107.475 57.016L107.613 56.2179L104.513 55.6824Z" fill="white"/>
        <path d="M91.8751 75.6193C91.8751 75.6193 91.7641 73.4443 91.2024 72.4385C90.6407 71.4327 91.9469 71.1975 92.5936 72.1446C93.2402 73.0916 93.7039 75.7108 93.7039 75.7108C93.7039 75.7108 92.5805 76.3835 91.8751 75.6193Z" fill="url(#paint14_linear_121_3819)"/>
        <path d="M96.2058 81.2299C96.0906 81.4189 95.9287 81.5749 95.7355 81.6829C95.5424 81.791 95.3247 81.8474 95.1035 81.8468C94.8822 81.8461 94.6648 81.7884 94.4723 81.6792C94.2799 81.57 94.1188 81.413 94.0047 81.2234C93.2536 80.002 92.1237 78.0295 91.9147 76.9976C91.6012 75.4431 91.3856 73.3596 93.4561 72.9611C95.5266 72.5627 98.2175 72.6933 98.2175 72.6933C98.2175 72.6933 98.8968 74.6136 97.1986 74.6201L98.4918 75.1753C98.4918 75.1753 98.9948 76.7232 97.4403 76.4424L98.7988 77.3307C98.7988 77.3307 98.7988 78.7806 97.5644 78.3692C96.6761 78.0752 96.9961 79.9498 96.2058 81.2299Z" fill="url(#paint15_linear_121_3819)"/>
        <path d="M146.269 63.3469C142.193 61.3613 140.632 61.4005 138.921 62.8962L141.814 65.1626C142.748 66.0117 142.572 66.8869 142.213 67.6315C141.775 68.5394 142.572 69.8392 143.904 70.2637C144.871 70.5772 145.805 70.3421 146.674 69.1207L147.438 67.625C147.203 66.5212 147.477 64.2286 146.269 63.3469Z" fill="url(#paint16_linear_121_3819)"/>
        <defs>
        <linearGradient id="paint0_linear_121_3819" x1="193.559" y1="207.133" x2="59.1688" y2="62.0765" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint1_linear_121_3819" x1="118986" y1="72984" x2="37510.8" y2="131852" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint2_linear_121_3819" x1="47445.6" y1="30630.3" x2="64720.6" y2="106699" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint3_linear_121_3819" x1="150.747" y1="100.787" x2="97.232" y2="1.7846" gradientUnits="userSpaceOnUse">
        <stop stop-color="#53D8FF"/>
        <stop offset="1" stop-color="#3840F7"/>
        </linearGradient>
        <linearGradient id="paint4_linear_121_3819" x1="133.095" y1="16.4761" x2="46.5791" y2="116.817" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint5_linear_121_3819" x1="94.2266" y1="45.8921" x2="116.517" y2="25.3846" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint6_linear_121_3819" x1="31099.2" y1="19344" x2="27579.6" y2="15361.7" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint7_linear_121_3819" x1="7940.52" y1="5033.94" x2="7088.52" y2="3996.76" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint8_linear_121_3819" x1="46787.2" y1="11004.9" x2="54504" y2="11004.9" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint9_linear_121_3819" x1="30166.7" y1="12269" x2="65143.3" y2="43867.3" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint10_linear_121_3819" x1="257.466" y1="187.861" x2="30.0269" y2="122.305" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint11_linear_121_3819" x1="325708" y1="292715" x2="323434" y2="527265" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint12_linear_121_3819" x1="42374.1" y1="212600" x2="82305" y2="121438" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint13_linear_121_3819" x1="164178" y1="53723.6" x2="122640" y2="116327" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F3CD12"/>
        <stop offset="1" stop-color="#DEB900"/>
        </linearGradient>
        <linearGradient id="paint14_linear_121_3819" x1="6812.42" y1="6026.62" x2="7600" y2="5229.57" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint15_linear_121_3819" x1="16510.2" y1="11795" x2="18311.7" y2="9562.11" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint16_linear_121_3819" x1="4395.22" y1="7165.12" x2="5622.62" y2="5440.46" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "DiagnosticoScreen",
    props:{
        star1:{
                type: String,
                required: true
        },
        star2:{
                type: String,
                required: true
        },
        star3:{
                type: String,
                required: true
        },
        star4:{
                type: String,
                required: true
        },
        star5:{
                type: String,
                required: true
        },
    }
}
</script>
