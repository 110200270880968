<template>
    <div>
        <div class="container pt-4">
            <header-component :showBack="true" urlBack="/home"/>

            <div class="box-carousel" v-if="loading">
                <v-row class="text-center">
                    <v-col>
                        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
                    </v-col>
                </v-row>
            </div>

            <div class="box-carousel" v-if="!loading">
                <v-row>
                    <h3 class="title">Níveis</h3>
                </v-row>
                <v-row>
                    <span class="subtitle">De acordo com seu diagnóstico, escolha um nível para darmos continuidade a essa jornada.</span>
                </v-row>
                <v-row>
                    <v-col class="mt-2" cols="6" sm="3" md="3" v-for="item in lstDiagnostico" :key="item.software.id">
                        <lista-software-card-lista-software :icone="item.software.icone" :qtdAulas="item.software.aulas.length" v-if="showCard" />
                    </v-col>
                </v-row>
                <v-row>
                    <span class="subtitle mt-3 mb-3">Selecione o nível</span>
                </v-row>
                <v-row>
                    <v-col cols="12" v-for="item in lstNiveis" :key="item.id" v-on:click="BuscaAulas(item.id)" style="cursor:pointer">
                        <card-nivel-lista :nivel="item"/>
                    </v-col>
                </v-row>
               
                
            </div>
            
        </div>
    </div>
</template>
<style scoped>
    .input-search, .v-text-field__slot{
        border: none;
        border-radius: 17.3394px !important;
    }
    .color-arrow{
        fill: '#004FF3f' !important;
    }
    .link{
        cursor: pointer;
    }
    .img-arrow{
        width: 100%;
    }
    .btn-no-knows{
        background: #C4C4C4 !important;
        border-radius: 16.1035px !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 27px !important;
        text-align: center !important;
        height: 52.36px !important;
        color: #000000 !important;
    }
    .btn-no-knows-active{
        background: #fff !important;
        border-radius: 16.1035px !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 27px !important;
        text-align: center !important;
        height: 52.36px !important;
        color: #004FF3 !important;
        border: 2px inset #004FF3 !important;
    }
    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 40.2589px;
        line-height: 49px;

        color: #4A4A4A;
    }
    .subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20.7657px;
        line-height: 31px;

        color: #4A4A4A;
    }
    .btn-next{
        background: #004FF3 !important;
        border-radius: 11.1394px !important;
        color: #fff !important;
        height: 52.36px !important;
    }
    body{
        background: red !important;
    }
    @media only screen and (max-width: 600px) {
        .title{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 900;
            font-size: 20.2589px;
            line-height: 20px;
    
            color: #4A4A4A;
        }
        .subtitle{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16.7657px;
            line-height: 18px;
    
            color: #4A4A4A;
        }
        .img-arrow{
            width: 100%;
        }
    }
    .test{
        background-color: #A0A0A0 !important;
    }
</style>
<script>
import client from '@/services/http/client/client';
export default {
    name: "ListaNivel",
    data() {
        return {
            showCard: false,
            lstDiagnostico: [],
            lstNiveis: [],
            loading: true,
        }
    },
    mounted(){
        this.loading = true
        this.BuscaSoftware();
        this.BuscaNiveis()
        this.loading = false
    },
    created(){
        require('../../../../public/assets/css/home-aluno.css');
    },
    methods:{
        BuscaSoftware(){
            
            client.get('/diagnostico-by-student?all=1')
                .then( response => {
                    this.lstDiagnostico = response.data;
                    this.lstDiagnostico = this.lstDiagnostico.filter(item => item.software.id == this.$route.params.software_id)
                    if (this.lstDiagnostico.length == 1) {
                        this.showCard = true
                    }
                    
                });
        },
        BuscaNiveis(){
            client.get('/niveis')
                .then( response => {
                    this.lstNiveis = response.data;
                });
        },
        BuscaAulas(nivel){
            location.href = '/lista-aulas/'+this.$route.params.software_id+'/'+nivel
        }
    }
}
</script>