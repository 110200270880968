<template>
    <div>
        <div class="container pt-4">
            <header-component :showBack="true" :urlBack="'/selecao-nivel/' + this.softwareId"/>
            <div class="box-carousel">
                <v-row>
                    <h3 class="title">Vídeo Aulas + Dicas de Especialistas</h3>
                </v-row>
                <v-row>
                    <span class="subtitle">Aqui você encontra uma ou mais aulas sobre o mesmo tema, além de dicas exclusivas de especialistas. Escolha a aula de sua preferência e bora melhorar sua performance! </span>
                </v-row>
                <v-row>
                    <v-col class="mt-2" cols="6" sm="3" md="3" v-for="item in lstDiagnostico" :key="item.software.id">
                        <lista-software-card-lista-software :icone="item.software.icone" :qtdAulas="item.software.aulas.length" v-if="showCard" />
                    </v-col>
                </v-row>
                <v-row>
                    <span class="subtitle mt-3 mb-3"><strong>Nível {{this.nivel.descricao}} - Tópicos</strong></span>
                </v-row>
                <v-row v-if="lstAulas.length > 0">
                    <v-col cols="12" v-for="item in lstAulas" :key="item.aulas_id" style="cursor:pointer">
                        <card-aula-topico-lista :aula="item" :software-id="softwareId" :topico-id="topico_id"/>
                    </v-col>
                </v-row>
                <v-row v-if="lstAulas.length == 0">
                    <v-col cols="12">
                        <b-alert variant="danger" show>Não Há Aulas cadastradas para este Tópico</b-alert>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .input-search, .v-text-field__slot{
        border: none;
        border-radius: 17.3394px !important;
    }
    .color-arrow{
        fill: '#004FF3f' !important;
    }
    .link{
        cursor: pointer;
    }
    .img-arrow{
        width: 100%;
    }
    .btn-no-knows{
        background: #C4C4C4 !important;
        border-radius: 16.1035px !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 27px !important;
        text-align: center !important;
        height: 52.36px !important;
        color: #000000 !important;
    }
    .btn-no-knows-active{
        background: #fff !important;
        border-radius: 16.1035px !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 27px !important;
        text-align: center !important;
        height: 52.36px !important;
        color: #004FF3 !important;
        border: 2px inset #004FF3 !important;
    }
    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 40.2589px;
        line-height: 49px;

        color: #4A4A4A;
    }
    .subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20.7657px;
        line-height: 31px;

        color: #4A4A4A;
    }
    .btn-next{
        background: #004FF3 !important;
        border-radius: 11.1394px !important;
        color: #fff !important;
        height: 52.36px !important;
    }
    body{
        background: red !important;
    }
    @media only screen and (max-width: 600px) {
        .title{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 900;
            font-size: 20.2589px;
            line-height: 20px;
    
            color: #4A4A4A;
        }
        .subtitle{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16.7657px;
            line-height: 18px;
    
            color: #4A4A4A;
        }
        .img-arrow{
            width: 100%;
        }
    }
    .test{
        background-color: #A0A0A0 !important;
    }
</style>
<script>
import client from '@/services/http/client/client';
export default {
    name: "ListaAulasTopico",
    data() {
        return {
            showCard: false,
            lstAulas: [],
            softwareId: 0,
            nivel: {},
            lstDiagnostico: [],
        }
    },
    mounted(){
        this.topico_id = parseInt(this.$route.params.topico_id)
        this.BuscaAulas()
        this.BuscaSoftware()
    },
    created(){
        require('../../../../public/assets/css/home-aluno.css');
    },
    methods:{
        BuscaAulas(){
            client.get('aulas-by-topico/' + this.topico_id)
                .then( response => {
                    this.softwareId = parseInt(response.data.softwareId)
                    this.nivel = response.data.nivel
                    this.lstAulas = response.data.aulas
                    console.log(this.softwareId)
                })
                .catch(() => {
                    location.href = '/login';
                })
        },
        BuscaSoftware(){
            client.get('/diagnostico-by-student?all=1')
                .then( response => {
                    this.lstDiagnostico = response.data;
                    console.log(this.softwareId)
                    this.lstDiagnostico = this.lstDiagnostico.filter(item => item.software.id == this.softwareId)
                    if (this.lstDiagnostico.length == 1) {
                        this.showCard = true
                    }
                })
                .catch(() => {
                    location.href = '/login';
                })
        },
       
    }
}
</script>