<template>
    <v-card elevation="2" class="card-software d-flex align-items-center">
        <v-col cols="12">
            <span class="texto-nivel">{{nivel.descricao}}</span>
        </v-col>
    </v-card>
</template>

<style scoped>
    .card-software{
        background: #FFFFFF;
        border-radius: 11.0245px;
        height: 75.6px;
    }
    .texto-nivel{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18.8991px;
        line-height: 23px;
        color: #4A4A4A;
    }
   
</style>
<script>
export default {
    name: "CardNivelLista",
    data() {
        return {
           
        }
    },
    mounted(){
    },
    methods:{
    },  
    props:{
       nivel:{
        type:Object,
        required: true,
       },
    }
}
</script>