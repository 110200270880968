<template>
    <div class="container pt-4">
        <div class="row">
            <div class="col-12 d-flex flex-row-reverse bd-highlight">
                <img src="assets/img/logo-white.png" class="img-logo-registro" alt="Logotipo branco"> 
            </div>
        </div>
        <div class="row">
            <div class="box-register">
                <form @submit="gravaStep2">
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <h3 class="titulo-registro">Agora precisamos saber mais sobre você</h3>
                        </v-col>
                        <v-col cols="12">
                            <span class="descricao-registro">Essa informação é de extrema importância, pois irá nos ajudar a separar o melhor conteúdo para você!</span>
                        </v-col>
                        <v-col cols="12">
                            <v-select-custom
                                :options="lstCursos"  
                                item-value="id" 
                                label="nome"
                                @input="setCursoSelected"
                                :dark="true"
                                :light="true"
                                placeholder="Área de Atuação"
                                label-test="Área de Atuação"
                                required
                            >
                                <template v-slot:no-options>
                                    <span style="color: #fff;">Cursos não localizados</span>
                                </template>
                            </v-select-custom>
                        </v-col>
                        <v-col cols="12">
                            <v-select-custom
                                :options="lstFaculdades"  
                                item-value="id" 
                                label="nome"
                                @input="setFaculdadeSelected"
                                :dark="true"
                                :light="true"
                                placeholder="Ramo de Atuação"
                                label-test="Ramo de Atuação"
                                required
                            >
                                <template v-slot:no-options>
                                    <span style="color: #fff;">Faculdades não localizadas</span>
                                </template>
                            </v-select-custom>
                        </v-col>
                        <v-col cols="12">
                            <v-select-custom
                                :options="lstSemestres"  
                                item-value="id" 
                                label="nome"
                                @input="setSemestreSelected"
                                :dark="true"
                                :light="true"
                                placeholder="Finalidade de Uso"
                                label-test="Finalidade de Uso"
                                required
                            >
                                <template v-slot:no-options>
                                    <span style="color: #fff;">Finalidades não localizadas</span>
                                </template>
                            </v-select-custom>
                        </v-col>
                        <termos-screen/>
                        <v-col cols="12">
                            <v-checkbox
                                v-model="aceitouTermos"
                                label="Aceito os termos de uso"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <v-btn 
                                :x-large="true" 
                                class="btn-lg col-12 btn-warning" 
                                :loading="loadingBtn" 
                                v-on:click="gravaStep2()"
                                v-if="submitEnabled"
                            >
                                CADASTRAR
                            </v-btn>
                        </v-col>
                    </v-row>
                </form>
            </div>
        </div>
        
    </div>
</template>
<style scoped>
    .btn-warning{
        background-color: #EF7D00 !important;
        color: #FFFFFF;
        border-color: #EF7D00 !important;
    }
    .titulo-registro, .descricao-registro{
        color: #fff;
    }
</style>
<style>
.v-label{
    color: #FFF !important;
}
.v-icon.v-icon {
    color: #FFF !important;
}
</style>
<script>
    import client from '@/services/http/client/client';
    export default {
        name: "EnsinoStep2",
        data () {
            return {
                semestre:null,
                cursos_id:null,
                faculdades_id:null,
                lstCursos: [],
                lstFaculdades: [],
                lstSemestres: [],
                loadingBtn:false,
                aceitouTermos: false
            };
        },
        mounted() {
            this.fetchFaculdades()
            this.fetchCursos()
            this.fetchSemestres()
        },
        created(){
            document.getElementById('css').setAttribute('style','background-color:#004ff3 !important;')
        },
        methods:{
            setCursoSelected(value){
                this.cursos_id = value.id
            },
            setFaculdadeSelected(value){
                this.faculdades_id = value.id
            },
            setSemestreSelected(value){
                this.semestre = value.id
            },
            fetchFaculdades(){
                client.get('/faculdades')
                .then( response => {
                    this.lstFaculdades = response.data;
                })
            },
            fetchCursos(){
                let url = "/cursos";
                client.get(url)
                .then( response => {
                    this.lstCursos = response.data;
                })
            },
            fetchSemestres(){
                /*let url = "/semestres";
                client.get(url)
                .then( response => {
                    this.lstSemestres = response.data;
                })*/
                this.lstSemestres = [
                    {
                        "id": 11,
                        "nome": "Estudantil"
                    },
                    {
                        "id": 12,
                        "nome": "Hobby"
                    },
                    {
                        "id": 13,
                        "nome": "Na Empresa"
                    },
                    {
                        "id": 14,
                        "nome": "Uso Individual"
                    },
                ]
            },
            gravaStep2(){
                client.post('/usuario/ensino', {
                    cursos_id: this.cursos_id,
                    faculdades_id:this.faculdades_id,
                    semestre:this.semestre,
                    aceitou_termos_uso:this.aceitouTermos
                })
                .then(
                    (response) => {
                        this.$swal.fire({
                            icon: response.data.type,
                            reverseButtons: true,
                            title: response.data.msg,
                            showCancelButton: false,
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Ok',
                            showLoaderOnConfirm: true,
                        }).then(() => {
                            this.loadingBtn = false;
                            window.location.href = '/welcome'
                        })
                    }
                )
                .catch(error => {
                    this.$swal.fire({
                        icon: error.data.type,
                        reverseButtons: true,
                        title: 'Ocorreu um erro ao gravar seus Dados. ',
                        showCancelButton: false,
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Ok',
                        showLoaderOnConfirm: true,
                    }).then(() => {
                        this.loadingBtn = false;
                    })
                    
                })
                .finally(() => {
                    this.loadingBtn = false;
                });
            },
        },
        computed: {
            submitEnabled() {
                return this.aceitouTermos && this.semestre !== null && this.cursos_id !== null && this.faculdades_id !== null
            }
        }
    }
</script>
