<template>
    <v-row align="center" justify="center" class="d-inline-flex p-2 border-card mb-3">
        <v-col cols="4" md="4">
            <v-img :src="software.icone" 
                height="120px" 
                width="120px"
                v-on:click="redirectTo('/selecao-nivel/' + software.id)"
                style="cursor:pointer;"
            />
        </v-col>
        <v-col cols="8" md="8">
            <br/>
            <h3 class="selecione" v-if="!detalhado">Selecione o seu nível de conhecimento em {{software.nome}}</h3>
        </v-col>
        <v-col cols="12">
            <v-slider 
                max="100" 
                min="0" 
                color="#0A83F3" 
                height="0" 
                background-color="#EEEEEE"
                v-model="diagnosticoObj.percentual_progresso"
                :disabled="true"
            />
            <span class="span-progresso">Progresso: {{diagnosticoObj.percentual_progresso}} %</span>
            <v-row class="d-flex align-content-between flex-wrap" >
                <v-col cols="12" md="6">
                    <v-rating
                        empty-icon="mdi-star"
                        full-icon="mdi-star"
                        half-icon="mdi-star-half-full"
                        half-increments
                        hover
                        color="#F3CD12"
                        length="5"
                        size="35"
                        readonly
                        :value="parseFloat(this.diagnosticoObj.nivel)"
                    ></v-rating>
                </v-col>
                <v-col cols="12" md="6" >
                    <button v-if="!detalhado" class="saber-nivel" v-on:click="SelecionarAulas()">Clique aqui e saiba seu nível de conhecimento</button>
                </v-col>
            </v-row>
            
        </v-col>
        <b-modal :hide-footer="true" :hide-header="true" :id="'modalAulas_' + software.id" class="bg-grey">
            <modal-aulas :software="software" v-on:calculaPercentual="calculaPercentual" />
        </b-modal>        
    </v-row>
    
</template>
<style>
    
    .v-slider--horizontal .v-slider__track-container {
        height:11.46px;
    }
    .v-slider__thumb{
        width: 25.79px !important;
        height: 25.79px !important;
        background: #0068C8 !important;
        border: 5.73134px solid #0A83F3 !important;
        left: -14px !important;
    }
    
    .v-slider__track-fill{
        background: #0068C8 !important;
    }
    .modal-dialog, .modal-body, .modal-content{
        background-color:#F0F0F0;
        border-radius: 20px;
    }
   

</style>
<style scoped>
    .modal-footer{
        display: none !important;
    }
    .border-card:hover{
        border: 2px solid #004FF3;
    }
    .bg-grey{
        background-color: #EEEEEE !important;
    }
    .border-card{
        border: 2px solid white;
        border-radius: 14.3284px;
        padding:0px;
    }
    .saber-nivel{

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 15.7612px;
        line-height: 19px;

        /* Color Principal */

        color: #004FF3;

    }
    .selecione{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 25.9254px;
        line-height: 28px;
        color: #4A4A4A;
    }
    .span-progresso{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14.3284px;
        line-height: 17px;
        color: #0A83F3;
    }

   @media only screen and (max-width: 600px) {
    .selecione{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 12.9254px;
        line-height: 18px;
    
        color: #4A4A4A;
    }
   }
</style>
<script>
    export default {
        name: "CardDiagnostico",
        data() {
            return {
                showModal:false,
                diagnosticoObj: {}
            }
        },
        methods:{
            SelecionarAulas(){
                this.$bvModal.show('modalAulas_' + this.software.id)
            },
            calculaPercentual(qtdAulas,qtdAulasAssistidas){
                this.diagnosticoObj.percentual_progresso = (qtdAulasAssistidas * 100) / qtdAulas;
                this.diagnosticoObj.nivel = this.diagnosticoObj.percentual_progresso / 20;
            },
            redirectTo(url){
                if (this.detalhado) {
                    location.href = url
                }
                
            }
        },
        props:{
            software:{
                type: Object,
                required: true
            },
            diagnostico:{
                type: Object,
                required: true
            },
            detalhado:{
                type: Boolean,
                required: false,
                default: false
            }
        },
        mounted(){
            this.diagnosticoObj = this.diagnostico
            
        }

    }
</script>