import { render, staticRenderFns } from "./SoftwareFinalizarScreen.vue?vue&type=template&id=32d3ef53&scoped=true&"
import script from "./SoftwareFinalizarScreen.vue?vue&type=script&lang=js&"
export * from "./SoftwareFinalizarScreen.vue?vue&type=script&lang=js&"
import style0 from "./SoftwareFinalizarScreen.vue?vue&type=style&index=0&id=32d3ef53&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32d3ef53",
  null
  
)

export default component.exports