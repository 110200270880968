<template>
    <b-form @submit.prevent="formSubmit()">
    <div style="background-color:#F0F0F0">
        <v-card-title class="text-h5 grey lighten-2">
            <v-row>
                <v-col cols="12">
                    <span class="title">Contato</span>
                </v-col>
            </v-row>
        </v-card-title>

        <v-card-text>
            <v-row>
               <span>Respondida por: </span>
            </v-row>

            <v-row>
                <v-col v-if="showSuccess">
                    <b-alert variant="success" show >{{alertMessage}}</b-alert>
                </v-col>
                <v-col  v-if="showError">
                    <b-alert variant="danger" show>{{alertMessage}}</b-alert>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="12" md="12">
                    <b-form-group
                        label="Assunto"
                        label-for="input-1"
                    >
                        <b-form-input
                            id="Assunto"
                            type="text"
                            placeholder="Assunto"
                            v-model="assunto"
                            :disabled="reply"
                            required
                        />
                    </b-form-group>
                </v-col>

                <v-col cols="12" md="12">
                    <b-form-textarea
                        id="textarea"
                        v-model="mensagem"
                        placeholder="Mensagem"
                        rows="3"
                        max-rows="6"
                        :disabled="reply"
                        required
                    ></b-form-textarea>
                </v-col>

            </v-row> 
            
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-row >
                <v-col class="d-flex justify-content-between">
                    <b-button :loading="loadingBtn" variant="dark" class="text-white" type="button" v-on:click="fecharModal()">
                        Fechar
                    </b-button>
                    <b-button :loading="loadingBtn" variant="primary" class="text-white" type="submit">
                        Salvar
                    </b-button>
                </v-col>
               
            </v-row>
        </v-card-actions>
    </div>
    </b-form>
</template>
<script>
    import client from '@/services/http/client/client';
    export default {
        name: "ModalAulas",
        data() {
            return {
                loadingBtn:false,
                showSuccess:false,
                alertMessage: '',
                showError:false,
                assunto:'',
                mensagem:'',
                reply: false
            };
        },
        props:{
            contato:{
                type: Object,
                required: false
            }
        },
        mounted(){
            if (this.contato) {
                this.assunto = this.contato.assunto
                this.mensagem = this.contato.mensagem
            }
        },
        methods: {
            fecharModal(){
                this.$bvModal.hide('contato')
            },
            formSubmit(){
                this.loadingBtn = true;
                if (this.mensagem != "" && this.assunto != "") {
                    client.post('contato',{
                        assunto: this.assunto,
                        mensagem: this.mensagem
                    })
                    .then( (response) => {
                        this.loadingBtn = false;
                        this.showError = !(response.data.type != "error")
                        this.showSuccess = (response.data.type != "error")
                        this.alertMessage = response.data.msg
                        this.fecharModal()
                    })
                    .catch(function (error) {
                        this.alertMessage = error
                        this.showError = true
                        this.showSuccess = false
                        this.loadingBtn = false;
                    })
                } else {
                    this.loadingBtn = false;
                    this.alertMessage = 'Os campos assunto e mensagem são obrigatórios'
                    this.showError = true
                    this.showSuccess = false
                }
                
            }
        },
    }
</script>