import store from "@/store"

export default async (to, from, next) => {
    if (to.name != 'login' && to.name != 'validar-codigo' && to.name != 'recuperar-senha' && to.name != 'home' && to.name != 'registro' && !store.getters['hasToken']) {
        try{
            await store.dispatch('checkToken')
            await store.dispatch('setRoute',to.name)
            next()
        } catch (err) {
            next({name: 'login'})
        }
    } else {
        await store.dispatch('setRoute',to.name)
        if (to.name == 'login' && store.getters['hasToken']) {
            next({name: 'home-aluno'})
        } else {
            next()
        }
    }
}