import Vue from 'vue'
import Vuex from 'vuex'
import client from '@/services/http/client/client'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    token: '',
    user: {},
    contatos:[],
    route: '',
  },
  getters: {
    token: state  => state.token,
    user: state   => state.user,
    hasToken: state => !!state.token,
    contatos: state   => state.contatos,
    route: state   => state.route,
  },
  mutations: {
    setToken: (state, token) => state.token = token,
    setUser: (state, user) => state.user = user,
    setContatos: (state, contatos) => state.contatos = contatos,
    setRoute: (state, route) => state.route = route,
  },
  actions: {
    setToken:  ({commit}, token) => commit('setToken', token),
    setRoute:  ({commit}, route) => commit('setRoute', route),
    setUser: ({commit}, user) => commit('setUser', user),
    setContatos: ({commit}, contatos) => commit('setContatos', contatos),
    checkToken({dispatch, state}){
      if (state.token) {
        return Promise.resolve(state.token)
      }

      const token = localStorage.getItem('token')
      
      if (!token) {
        return Promise.reject(new Error('Token Inválido'))
      }

      return dispatch('loadSession')
    },
    loadSession({dispatch}){
      client.get('/auth/me')
      .then(response => {
        dispatch('setToken', localStorage.getItem('token'))
        dispatch('setUser', response.data.user)
        localStorage.setItem('token', localStorage.getItem('token'))
        localStorage.setItem('id',response.data.user.id)
      })
      .catch(() => {
        localStorage.removeItem('token')
        localStorage.removeItem('id')
        dispatch('setToken',null)
        dispatch('setUser', {})
        dispatch('setContatos', {})
      })
    },
    logout({dispatch}){
      localStorage.removeItem('token')
      localStorage.removeItem('id')
      dispatch('setToken',null)
      dispatch('setUser', {})
      dispatch('setContatos', {})
      dispatch('setRoute', {})
    },
    async loadMessages({dispatch}){
      await client.get('/contato')
      .then(response => {
        dispatch('setContatos', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
    },
    async readAllMessages(){
      await client.get('/contato/read-all')
      .then( () => {
        this.loadMessages();
      })
      .catch((error) => {
        console.log(error)
      })
    },
  },
  modules: {
  }
})
