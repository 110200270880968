<template>
    <v-card class="mt-2"  >
        <v-card-title>
            {{title}}
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="3" v-for="diagnostico in diagnosticos" :key="diagnostico.software.id" >
                    <v-img :src="diagnostico.software.icone" v-on:click="redirectTo('/selecao-nivel/' + diagnostico.software.id)" style="cursor:pointer;"/>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<style scoped>
    .v-card__title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20.3799px;
        line-height: 36px;
        color: #4A4A4A;
    }
    @media only screen and (max-width: 600px) {
        .v-card__title{
            font-size: 18.3799px;
            line-height: 26px;
        }
    }
</style>
<script>
    export default {
        name: "CardSoftware",
        props:{
            title:{
                type: String,
                required: true
            },
            diagnosticos:{
                required: true
            }
        },
        data() {
            return {
              
            }
        },
        mounted() {
            
        },
        created(){
        },
        methods:{
            redirectTo(url){
                location.href = url
            }
        }

    }
</script>