<template>
    <div>
        <div class="container pt-4">
            <header-component/>
            <div class="box-carousel" v-if="loading">
                <v-row class="text-center">
                    <v-col>
                        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
                    </v-col>
                </v-row>
            </div>
            <div class="box-carousel" v-if="!loading">
                <v-row>
                    <h3 class="title">Softwares</h3>
                </v-row>
                <v-row>
                    <span class="subtitle">Escolha um software abaixo para dar continuidade a sua evolução tecnológica.</span>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Buscar software..."
                            placeholder="Buscar software..."
                            solo
                            class="input-search"
                            append-icon="fa fa-search"
                            v-model="nomeSoftware"
                            @keyup="BuscaSoftware()"
                        ></v-text-field>
                    </v-col>
                </v-row>
                
                <div class="align-items-center d-flex">
                    <lista-software :lstDiagnostico="lstDiagnostico"/>
                </div>
                
            </div>
            
        </div>
    </div>
</template>
<style scoped>
    .input-search, .v-text-field__slot{
        border: none;
        border-radius: 17.3394px !important;
    }
    .color-arrow{
        fill: '#004FF3f' !important;
    }
    .link{
        cursor: pointer;
    }
    .img-arrow{
        width: 100%;
    }
    .btn-no-knows{
        background: #C4C4C4 !important;
        border-radius: 16.1035px !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 27px !important;
        text-align: center !important;
        height: 52.36px !important;
        color: #000000 !important;
    }
    .btn-no-knows-active{
        background: #fff !important;
        border-radius: 16.1035px !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 27px !important;
        text-align: center !important;
        height: 52.36px !important;
        color: #004FF3 !important;
        border: 2px inset #004FF3 !important;
    }
    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 40.2589px;
        line-height: 49px;

        color: #4A4A4A;
    }
    .subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20.7657px;
        line-height: 31px;

        color: #4A4A4A;
    }
    .btn-next{
        background: #004FF3 !important;
        border-radius: 11.1394px !important;
        color: #fff !important;
        height: 52.36px !important;
    }
    body{
        background: red !important;
    }
    @media only screen and (max-width: 600px) {
        .title{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 900;
            font-size: 20.2589px;
            line-height: 20px;
    
            color: #4A4A4A;
        }
        .subtitle{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16.7657px;
            line-height: 18px;
    
            color: #4A4A4A;
        }
        .img-arrow{
            width: 100%;
        }
    }
    .test{
        background-color: #A0A0A0 !important;
    }
</style>
<script>
import client from '@/services/http/client/client';
export default {
    name: "HomeAlunoScreen",
    data() {
        return {
            nomeSoftware:null,
            lstDiagnostico: [],
            lstDiagnosticoTemp: [],
            loading: true,
        }
    },
    mounted(){
        this.fetchDiagnosticos()
    },
    created(){
        require('../../../public/assets/css/home-aluno.css');
    },
    methods:{
        BuscaSoftware(){
            this.loading = true;
    
            if (this.nomeSoftware) {
                if (this.lstDiagnosticoTemp.length > 0) {
                    this.lstDiagnostico = this.lstDiagnosticoTemp;
                }

                this.lstDiagnosticoTemp = this.lstDiagnostico;
                
                const searchTerm = this.nomeSoftware.toUpperCase();
                
                this.lstDiagnostico = this.lstDiagnostico.filter(item => 
                    item.software.nome.toUpperCase().includes(searchTerm)
                );
            } else {
                this.lstDiagnostico = this.lstDiagnosticoTemp;
            }
            
            this.loading = false;
            
        },
        fetchDiagnosticos(){
            this.loading = true
            client.get('/diagnostico-by-student?all=1')
                .then( response => {
                    this.lstDiagnostico = response.data;
                    this.loading = false
                });
        },
    },  
    props:{
    }
}
</script>