<template>
    <div>
        <div class="col-12 mt-5 text-white">
            <span class="title-termos">
                <a style="cursor:pointer" v-on:click="open('TERMO_USO')"><u>Termos de uso</u></a> e 
                <a style="cursor:pointer" v-on:click="open('POLITICA_PRIVACIDADE')"><u>Política de Privacidade</u></a>
            </span>
        </div>
        <b-modal :hide-footer="true" :hide-header="true" id="termosUso" class="bg-grey">
            <span v-html="dadoConfiguracao"></span>
            <b-button class="mt-3" block @click="$bvModal.hide('termosUso')">Fechar</b-button>
        </b-modal>
    </div>
</template>
<script>
    import client from '@/services/http/client/client';
    export default {
        name: "TermoScreen",
        data() {
            return {
                logo: "/img/logo-white.png",
                dadoConfiguracao: '',
                lstConfiguracoes: []
            }
        },
        mounted(){
            this.fetchConfiguracoes()
        },
        methods:{
            fetchConfiguracoes(){
                client.get('/configuracoes')
                .then(response => {
                    this.lstConfiguracoes = response.data;
                    
                    
                })
                .catch(() => {
                    this.$swal.fire({
                        icon: 'error',
                        reverseButtons: true,
                        title: 'Ocorreu um erro ao buscar as configurações',
                        confirmButtonText: 'Ok',
                        showLoaderOnConfirm: true,
                    }).then(() => {
                        this.loadingBtn = false;
                    })
                })
            },
            open(config){
                this.$bvModal.show('termosUso')
                let termos = this.lstConfiguracoes.filter(item => item.nome_configuracao == config);
                if (termos.length > 0) {
                    this.dadoConfiguracao = termos[0].valor_configuracao
                }
            }
        },
    }
</script>