<template>
    <div>
        <v-list-item v-on:click="redirectTo(redirectUrl)">
            <v-list-item-icon>
                <v-icon style="color:#0259F3 !important;">{{icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="margin-left: 10px;">
                {{title}}
            </v-list-item-title>
        </v-list-item>
    </div>
</template>
<script>

  export default {
    name: "DrawerMenuItem",
    methods:{
        redirectTo(url){
            location.href = url;
        }
    },
    props:{
        redirectUrl: String,
        icon: String, 
        title: String
    }
  }
</script>
