<template>
    <div class="container pt-4">
        <div class="row">
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-between mt-5">
                    <span 
                        v-for="slide in slides" 
                        :key="slide.id" 
                        :class="(slide.active) ? 'active-slide' : 'inactive-slide'">
                    </span>
                </div>
                <img src="assets/img/logo-blue.svg" class="img-logo-registro" alt="Logotipo branco">
            </div>
        </div>
        <div class="row">
            <div class="box-carousel">
                <v-container>
                    <transition-group name="fade" tag="div">
                        <div v-for="i in [currentIndex]" :key="i" style="p-5">
                            <v-sheet>
                                <v-row>
                                    <v-img :src="currentImg.image" contain height="290" class="mt-3"/>
                                </v-row>
                                <v-row>
                                    <h3 class="title-carousel mt-2">{{currentImg.title}}</h3>
                                </v-row>
                                <v-row>
                                    <h3 class="content-carousel mt-2">{{currentImg.content}}</h3>
                                </v-row>
                                <v-row style="margin-top:60px">
                                    <span class="footer-carousel">{{currentImg.footer}}</span>
                                </v-row>
                            </v-sheet>
                        </div>
                    </transition-group>
                    <v-row>
                        <div class="d-flex justify-content-between mt-5 mb-5">
                            <v-btn v-on:click="prev" v-if="showPrevious" class="btn-prev"> 
                                <v-img src="assets/img/prev.svg" contain height="10"/> 
                            </v-btn>
                            <div v-if="!showPrevious"></div>
                            
                            <v-btn v-on:click="next" v-if="showNext" class="btn-next"> 
                                <span v-if="showFinalizar" class="btn-finalizar">Finalizar   </span>
                                <v-img src="assets/img/next.svg" contain height="10"/>
                            </v-btn>
                        </div>
                    </v-row>
                </v-container>
                
            </div>
        </div>
    
    </div>
</template>
<style scoped>
    .btn-finalizar{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 15.5227px;
        line-height: 19px;

        color: #FFFFFF;
    }
    .btn-next{
        background: #004FF3 !important;
        border-radius: 11.1394px !important;
        color: #fff !important;
        
        height: 52.36px !important;
    }
    .btn-prev{
        border-radius: 11.1394px !important;
        color: #fff !important;
        width: 52.36px !important;
        height: 52.36px !important;
        background: #EF7D00 !important;
    }
    .inactive-slide{
        background: #B3B3B3;
        border-radius: 9px;
        width: 18px;
        height: 18px;
        margin-left: 5px;
    }
    .active-slide{
        background: #004FF3;
        border-radius: 9px;
        width: 41.4px;
        height: 18px;
        margin-left: 5px;
    }
    .footer-carousel{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20.3587px;
        line-height: 25px;
        color: #004FF3;
    }
    .title-carousel {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 25.895px;
        line-height: 47px;

        color: #4A4A4A;
    }
    .content-carousel{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 21.2154px;
        line-height: 26px;

        color: #848484;
    }
    @media only screen and (max-width: 600px) {
        .title-carousel {
            font-size: 25.895px;
            line-height: 20px;
        }
        .content-carousel{
            font-size: 16.2154px;
            line-height: 26px;
        }
        .footer-carousel{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 15.3587px;
            line-height: 15px;
            color: #004FF3;
        }
    }
</style>
<script>
import client from '@/services/http/client/client';
export default {
    name: "CarouselEntrada",
    data() {
        return {
            currentIndex: 0,
            showPrevious: false,
            showNext: true,
            showFinalizar:false,
            slides: [
                {
                    id: 0,
                    image: "assets/img/CarouselEntrada/step1.svg",
                    title: "O que é um ZAPPER?",
                    content: "Zapper não é um Nerd! Zapper é quem possui a tecnologia necessária e suficiente para desenvolver sua profissão. Não há profissão sem software. Seja um Zapper!",
                    footer: "É simples! É ZippZapp!",
                    active: true,
                },
                {
                    id: 1,
                    image: "assets/img/CarouselEntrada/step2.svg",
                    title: "Faça seu Autodiagnóstico",
                    content: "Saiba agora quanto você conhece e quanto ainda falta aprender do software que você utiliza. Compare seus resultados com as expectativas de mercado e melhore sua performance.",
                    footer: "É fácil. É rápido. É ZippZapp",
                    active: false,
                },
                {
                    id: 2,
                    image: "assets/img/CarouselEntrada/step4.svg",
                    title: "Evolua sua Performance",
                    content: "ZippZapp possui uma plataforma de aprendizado gratuita para te ajudar a melhorar seus índices de performance. São aulas rápidas (no máximo 10 minutos) tópico por tópico. Assim você não perde tempo e foca naquilo que você precisa evoluir.",
                    footer: "É fácil. É rápido. É grátis. É ZippZapp",
                    active: false,
                },
            ],
        };
    },
    methods: {
        next: function() {
            this.alterStatus(false);
            this.currentIndex += 1;
            if(this.currentIndex >= this.slides.length){
                this.finalizouCarousel();
            }
            this.alterStatus(true);
            this.showPrevious = (this.currentIndex > 0);
            this.showFinalizar = (this.currentIndex+1 == this.slides.length);
            
        },
        prev: function() {
            this.alterStatus(false);
            this.currentIndex -= 1;
            if(this.currentIndex < 0){
                this.currentIndex = 0;
            }
            this.alterStatus(true);
            this.showPrevious = (this.currentIndex > 0);
            this.showFinalizar = (this.currentIndex+1 == this.slides.length);
        },
        alterStatus(status){
            this.slides[this.currentIndex].active = status;
        },
        finalizouCarousel(){
            client.post('/finalizou-carousel',{
                    primeiro_acesso: 1,
                })
                .then( () => {
                    location.href = '/seleciona-softwares';
                })
        }
    },
    computed: {
        currentImg: function() {
            return this.slides[Math.abs(this.currentIndex) % this.slides.length];
        },
    },
    created(){
        document.getElementById('css').setAttribute('style','background-color:#FFF !important;')
    }
}
</script>
