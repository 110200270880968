<template>
    <div class="container pt-4">
        <div class="row">
            <div class="col-12 d-flex flex-row-reverse bd-highlight">
                <img src="assets/img/logo-white.png" class="img-logo-registro" alt="Logotipo branco"> 
            </div>
        </div>
        <div class="row">
            <div class="box-register">
                <v-row>
                    <v-col cols="12" class="text-center">
                        <h3 class="titulo-registro">Obrigado pelo interesse em efetuar seu cadastro!</h3>
                    </v-col>
                    <v-col cols="12">
                        <span class="descricao-registro">Preencher os dados abaixo para completar o seu cadastro em nossa plataforma.</span>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            :hide-details="true"
                            :dark="true"
                            :light="true"
                            label="E-mail"
                            v-model="email"
                            outlined
                            type="email"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            :hide-details="true"
                            :dark="true"
                            :light="true"
                            label="Senha"
                            v-model="password"
                            outlined
                            type="password"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-btn :x-large="true" class="btn-lg col-12 btn-warning" :loading="loadingBtn" v-on:click="register()">
                            CADASTRAR
                        </v-btn>
                        
                    </v-col>
                </v-row>
            </div>
            
        </div>
    </div>
</template>
<style scoped>
    .btn-warning{
        background-color: #EF7D00 !important;
        color: #FFFFFF;
        border-color: #EF7D00 !important;
    }
    .titulo-registro, .descricao-registro{
        color: #fff;
    }
</style>
<script>
    import client from '@/services/http/client/client';
    import { mapActions } from 'vuex';
    export default {
        name: "RegistroForm",
        data () {
            return {
                name: null,
                email: null,
                password:null,
                loadingBtn:false,
            };
        },
        mounted() {
            
        },
        methods:{
            ...mapActions([
                'setToken',
                'setUser'
            ]),
            register(){
                this.loadingBtn = true
                if (this.password.length < 6) {
                    this.showAlert('A senha deve conter ao menos 6 caracteres!','error')
                } else {
                    client.post('/auth/register', {
                        email:this.email,
                        password:this.password,
                        password_confirmation:this.password
                    })
                    .then(
                        (response) => {
                            this.setToken('Bearer ' + response.data.userData.access_token)
                            localStorage.setItem('token', 'Bearer ' + response.data.userData.access_token)
                            localStorage.setItem('email',this.email)
                            this.setUser(response.data.userData.me)
                            window.location.href = '/validar-codigo/register'
                        }
                    )
                    .catch(() => {
                        this.showAlert('Ocorreu um erro ao criar seu cadastro!','error', 'Verifique com os dados digitados.')
                    })
                    .finally(() => {
                        this.loadingBtn = false;
                    });
                }
            },
            showAlert(msg, icon, text){
                this.$swal.fire({
                        icon: icon,
                        reverseButtons: true,
                        title: msg,
                        text: text,
                        showCancelButton: false,
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Ok',
                        showLoaderOnConfirm: true,
                    }).then(() => {
                        this.loadingBtn = false;
                    })
            }
        },
        created(){
            document.getElementById('css').setAttribute('style','background-color:#004ff3 !important;')
        }
    }
</script>