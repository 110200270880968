<template>
    <div class="container pt-4">
        <div class="row">
            <div class="col-12 d-flex flex-row-reverse bd-highlight">
                <img src="assets/img/logo-white.png" class="img-logo-registro" alt="Logotipo branco"> 
            </div>
        </div>
        <div class="row">
            <div class="box-register">
                <v-row>
                    <v-col cols="12" class="text-center">
                        <h3 class="titulo-registro">Bem-vindo ao ZippZapp!</h3>
                    </v-col>
                    <v-col cols="12">
                        <span class="descricao-registro">Para finalizarmos seu cadastro iremos coletar mais algumas informações para que  possamos nos conhecer melhor.</span>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            :hide-details="true"
                            :dark="true"
                            :light="true"
                            color="#fff"
                            label="Nome Completo"
                            v-model="name"
                            outlined
                            type="text"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            :hide-details="true"
                            :dark="true"
                            :light="true"
                            color="#fff"
                            label="Data de Nascimento"
                            v-model="data_nascimento"
                            outlined
                            required="required"
                            type="date"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select-custom
                            :options="lstEstados"  
                            item-value="id" 
                            label="title"
                            @input="setStateSelected"
                            :dark="true"
                            :light="true"
                            placeholder="Selecione um estado"
                        >
                            <template v-slot:no-options>
                                <span style="color: #fff;">Estados não localizados</span>
                            </template>
                        </v-select-custom>
                    </v-col>
                    <v-col cols="12">
                        <div data-app>
                            <v-select-custom
                                :options="this.lstCidades"  
                                item-value="id" 
                                label="title"
                                outlined
                                placeholder="Selecione a Cidade"
                                @input="setCidadeSelected"
                            >
                                <template v-slot:no-options >
                                    <span style="color: #fff;">Cidades não localizadas</span>
                                </template>
                            </v-select-custom>      
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-btn 
                            :x-large="true" 
                            class="btn-lg col-12 btn-warning" 
                            :loading="loadingBtn" 
                            v-on:click="gravaStep1()"
                        >
                            CADASTRAR
                        </v-btn>
                    </v-col>
                </v-row>
            
            </div>
        </div>
        
    </div>
</template>
<style scoped>
    .btn-warning{
        background-color: #EF7D00 !important;
        color: #FFFFFF;
        border-color: #EF7D00 !important;
    }
    .titulo-registro, .descricao-registro{
        color: #fff;
    }

</style>
<script>
    import client from '@/services/http/client/client';
    export default {
        name: "Step1Page",
        data () {
            return {
                estado_id:null,
                cidade_id:null,
                name:null,
                data_nascimento:null,
                lstEstados: [],
                lstCidades: [],
                loadingBtn:false,
            };
        },
        mounted() {
            this.fetchEstados()
        },
        created(){
            document.getElementById('css').setAttribute('style','background-color:#004ff3 !important;')
        },
        methods:{
            setStateSelected(value){
                this.estado_id = value.id
                this.fetchCidades()
            },
            setCidadeSelected(value){
                this.cidade_id = value.id
            },
            fetchEstados(){
                client.get('/estados')
                .then( response => {
                    this.lstEstados = response.data;                  
                })
            },
            fetchCidades(){
                let url = "/cidades?estado_id="+this.estado_id;
                client.get(url)
                .then( response => {
                    this.lstCidades = response.data;
                })
            },
            gravaStep1(){
                var nascimento = new Date(this.data_nascimento)
                var hoje = new Date();
                if (nascimento < hoje) {
                    client.post('/usuario/atualizar', {
                        name: this.name,
                        data_nascimento:this.data_nascimento,
                        cidade_id:this.cidade_id,
                        estado_id:this.estado_id
                    })
                    .then((response) => {
                            this.$swal.fire({
                                icon: response.data.type,
                                reverseButtons: true,
                                title: response.data.msg,
                                showCancelButton: false,
                                cancelButtonText: 'Cancelar',
                                confirmButtonText: 'Ok',
                                showLoaderOnConfirm: true,
                            }).then(() => {
                                this.loadingBtn = false;
                                window.location.href = '/ensino'
                            })
                        }
                    )
                    .catch(error => {
                        this.$swal.fire({
                            icon: error.data.type,
                            reverseButtons: true,
                            title: error.data.msg,
                            showCancelButton: false,
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Ok',
                            showLoaderOnConfirm: true,
                        }).then(() => {
                            this.loadingBtn = false;
                        })
                    })
                    .finally(() => {
                        this.loadingBtn = false;
                    });
                } else {
                    this.$swal.fire({
                        icon: 'error',
                        reverseButtons: true,
                        title: 'Data de Nascimento Inválida',
                        showCancelButton: false,
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Ok',
                        showLoaderOnConfirm: true,
                    }).then(() => {
                        this.loadingBtn = false;
                    })
                }

            },
        }
    }
</script>
