// we first import the axios library
import axios from 'axios'
// we get the base url from the environment variables

import store from '@/store'

/**
 * Axios basic configuration
 */
const config = {
  baseURL: 'https://admin.zippzapp.com.br/api/V1'
  //baseURL: 'http://localhost:8000/api/V1'
}

/**
 * Creating the instance of Axios
 * It is because in large-scale application, we may need
 * to consume APIs from more than a single server,
 */
const client = axios.create(config)

/**
 * Auth interceptors
 * @description Add auth tokens to every outgoing request.
 * @param {*} config
 */
const authInterceptor = config => {
  config.headers.Authorization = localStorage.getItem('token')
  config.headers['Access-Control-Allow-Origin'] = '*'
  return config
}

/**
 * Logger interceptors
 * @description Log app requests.
 * @param {*} config
 */
const loggerInterceptor = config =>
/** Add logging here */
  config

/** Adding the request interceptors */
client.interceptors.request.use(authInterceptor)
client.interceptors.request.use(loggerInterceptor)

/** Adding the response interceptors */
client.interceptors.response.use(
  response => Promise.resolve(response),
  error => {
    if (error.response.status === 500) {
      Event.$emit('error', 500, error.response.data.message)
    }
    
    if (error.response.status === 401) store.logout()
    const errorMessage = error.response.data ? error.response.data.message : error.errors
    error.response.data.message = errorMessage.length > 200
      ? JSON.parse(errorMessage.split('code :').pop()).error.message.split(':')[0]
      : errorMessage
    throw error
    // Promise.reject(error)
  }
)

export default client