<template>
    <div>
        <div class="container pt-4">
            <header-component :showBack="false"/>
            
            <v-card class="mt-3">
                <v-card-text>
                    <v-row>
                        <v-col v-if="showSuccess">
                            <b-alert variant="success" show >{{alertMessage}}</b-alert>
                        </v-col>
                        <v-col  v-if="showError">
                            <b-alert variant="danger" show>{{alertMessage}}</b-alert>
                        </v-col>
                    </v-row>
                    <b-form @submit.prevent="formSubmit()">
                        <v-row>
                            <v-col cols="12" md="6">
                                <b-form-group
                                    label="Nome Completo"
                                    label-for="input-1"
                                >
                                    <b-form-input
                                        id="name"
                                        type="text"
                                        placeholder="Nome Completo"
                                        v-model="user.name"
                                        required
                                    />
                                </b-form-group>
                            </v-col>

                            <v-col cols="12" md="6">
                                <b-form-group
                                    id="email"
                                    label="E-mail"
                                    label-for="input-1"
                                >
                                    <b-form-input
                                        id="email"
                                        type="email"
                                        placeholder="E-mail"
                                        v-model="user.email"
                                        disabled
                                    />
                                </b-form-group>
                            </v-col>

                            <v-col cols="12" md="4">
                                <b-form-group
                                    id="data_cadastro"
                                    label="Data de Cadastro"
                                    label-for="input-1"
                                >
                                    <b-form-input
                                        id="data_cadastro"
                                        type="text"
                                        v-model="user.data_cadastro"
                                        disabled
                                    />
                                </b-form-group>
                            </v-col>

                            <v-col cols="12" md="4">
                                <b-form-group
                                    id="data_cadastro"
                                    label="Data de Nascimento"
                                    label-for="input-1"
                                >
                                    <b-form-input
                                        type="date"
                                        v-model="user.data_nascimento"
                                    />
                                </b-form-group>
                            </v-col>

                            <v-col cols="12" md="4">
                                <b-form-group
                                    id="foto"
                                    label="Foto"
                                    label-for="input-1"
                                >
                                    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                                    
                                </b-form-group>
                            </v-col>
                            
                        </v-row>
                    </b-form>
                </v-card-text>
                <v-card-actions>
                    <v-row >
                        <v-col>
                            <b-button :loading="loadingBtn" variant="primary" class="text-white" type="submit" v-on:click="formSubmit()">
                                Salvar
                            </b-button>
                        </v-col>
                    </v-row>
                    
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>
<style scoped>
    .input-search, .v-text-field__slot{
        border: none;
        border-radius: 17.3394px !important;
    }
    .color-arrow{
        fill: '#004FF3f' !important;
    }
    .link{
        cursor: pointer;
    }
    .img-arrow{
        width: 100%;
    }
    .btn-no-knows{
        background: #C4C4C4 !important;
        border-radius: 16.1035px !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 27px !important;
        text-align: center !important;
        height: 52.36px !important;
        color: #000000 !important;
    }
    .btn-no-knows-active{
        background: #fff !important;
        border-radius: 16.1035px !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 27px !important;
        text-align: center !important;
        height: 52.36px !important;
        color: #004FF3 !important;
        border: 2px inset #004FF3 !important;
    }
    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 40.2589px;
        line-height: 49px;

        color: #4A4A4A;
    }
    .subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20.7657px;
        line-height: 31px;

        color: #4A4A4A;
    }
    .btn-next{
        background: #004FF3 !important;
        border-radius: 11.1394px !important;
        color: #fff !important;
        height: 52.36px !important;
    }
    body{
        background: red !important;
    }
    @media only screen and (max-width: 600px) {
        .title{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 900;
            font-size: 20.2589px;
            line-height: 20px;
    
            color: #4A4A4A;
        }
        .subtitle{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16.7657px;
            line-height: 18px;
    
            color: #4A4A4A;
        }
        .img-arrow{
            width: 100%;
        }
    }
    .test{
        background-color: #A0A0A0 !important;
    }
</style>
<script>
import client from '@/services/http/client/client';
import { mapActions } from 'vuex';
export default {
    name: "PerfilAluno",
    data() {
        return {
            user: {},
            file: '',
            imageFile: Object,
            imageUrl:Object,
            loadingBtn:false,
            showSuccess:false,
            alertMessage: '',
            showError:false
        }
    },
    mounted(){
        
    },
    created(){
        require('../../../public/assets/css/home-aluno.css');
        this.fetchUser()
    },
    methods:{
        ...mapActions([
            'setUser',
        ]),
        handleFileUpload(){
            this.file = this.$refs.file.files[0];
        },
        formSubmit() {
            let formData = new FormData();
            formData.append('foto', this.file);
            formData.append('name', this.user.name);
            formData.append('data_nascimento', this.user.data_nascimento);

            client.post( '/usuario/atualizar',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((response) => {
                    this.alertMessage = response.data.msg
                    this.showSuccess = (response.data.type != 'error') 
                    this.showError = !(response.data.type != 'error') 
                    this.loadSession()
                })
                .catch(function(){
                });
        },  
        fetchUser(){
            client.get('/auth/me')
            .then(response => {
                this.user = response.data.user
                this.setUser(this.user)
                console.log(this.user)
            })
            .catch(() => {
            })
        },
      
    }
}
</script>