<template>
    <div class="container pt-4">
        <div class="row">
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-between link">
                    
                </div>
                <img src="assets/img/logo-blue.svg" class="img-logo-registro" alt="Logotipo branco">
            </div>
        </div>
        <div class="row">
            <div class="box-carousel">
                <div class="row">
                    <v-row>
                        <h3 class="title">{{titulo}}</h3>
                    </v-row>
                    <v-row class="mt-3">
                        <span class="subtitle">{{subtitulo}}</span>
                    </v-row>               
                    <v-row class="d-flex mt-4">
                        <v-col cols="12" md="12" v-for="diagnostico in lstDiagnostico" :key="diagnostico.diagnostico.id">
                            <card-diagnostico :software="diagnostico.software" :diagnostico="diagnostico.diagnostico"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex flex-row-reverse">
                        <v-btn class="btn-next" :loading="loadingBtn" v-on:click="storeDiagnostico"> 
                            <v-img src="assets/img/next.svg" contain height="10"/>
                        </v-btn>
                    </v-row>
                </div>
            </div>
        </div>
        
   </div>
</template>
<style scoped>
    
    .link{
        cursor: pointer;
    }
    .img-arrow{
        width: 100%;
    }
   
    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 31.5224px;
        line-height: 38px;

        color: #4A4A4A;
    }
    .subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 17.194px;
        line-height: 21px;
        
        color: #7A7A7A;
    }
    .btn-next{
        background: #004FF3 !important;
        border-radius: 11.1394px !important;
        color: #fff !important;
        width: 55.35px !important;
        height: 55.35px !important;
        margin-bottom: 20px !important;
    }
    @media only screen and (max-width: 600px) {
        .title{
            font-size: 15.5224px;
            line-height: 19px;
            margin-top: 40px;
        }
        .subtitle{
            font-size: 10.194px;
            line-height: 21px;
        }
        .img-arrow{
            width: 100%;
        }
        .btn-next{
            width: 45.35px !important;
            height: 45.35px !important;
            bottom: 0 !important;
            margin-bottom: 20px;
        }
    }
</style>
<script>
import client from '@/services/http/client/client';
    export default {
        name: "SoftwareFinalizarScreen",
        data() {
            return {
                page: 1,
                lstDiagnostico: [],
                titulo: "Para finalizar, me conta um pouco sobre seu nível de conhecimento",
                subtitulo: "Caso não saiba definir seu nível, clique em “saiba seu nível de conhecimento” para fazer um checklist.",
                loadingBtn: false,
            }
        },
        mounted() {
            this.fetchDiagnosticos();
        },
        created(){
            document.getElementById('css').setAttribute('style','background-color:#FFF !important;')
        },
        methods:{
            fetchDiagnosticos(){
                client.get('/diagnostico-by-student?all=0')
                    .then( response => {
                        this.lstDiagnostico = response.data;
                    });
                    
            },
            storeDiagnostico(){
                this.loadingBtn = true;
                client.post('/diagnostico/atualizar',{
                    lstDiagnostico: this.lstDiagnostico,
                })
                .then( response => {
                    this.loadingBtn = false;
                    this.$swal.fire({
                        icon: response.data.type,
                        reverseButtons: true,
                        title: response.data.msg,
                        confirmButtonText: 'Ok',
                        showLoaderOnConfirm: true,
                    }).then(() => {
                        this.loadingBtn = false;
                        location.href= '/diagnostico'
                    })
                })
                .catch(function (error) {
                    this.$swal.fire({
                        icon: error.data.type,
                        reverseButtons: true,
                        title: error.data.msg,
                        confirmButtonText: 'Ok',
                        showLoaderOnConfirm: true,
                    }).then(() => {
                        this.loadingBtn = false;
                    })
                })
            },
        }

    }
</script>