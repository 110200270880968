<template>
    <div class="container pt-4">
        <div class="row">
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-between link" v-on:click="prev()">
                    <img src="assets/img/arrow-left.svg" class="img-arrow" />
                </div>
                <img src="assets/img/logo-blue.svg" class="img-logo-registro" alt="Logotipo branco">
            </div>
        </div>
        <div class="box-diagnostico">
            <v-row>
                <h3 class="title">Diagnóstico <br/> Completo</h3>
            </v-row>
            <v-row>
                <v-col cols="3" md="4" sm="4">
                    <h2>Total</h2>
                </v-col>
                <v-col cols="2" md="4" sm="4">
                    <h2 class="percentual">{{mediaPercentual}}%</h2>
                </v-col>
                <v-col cols="7" md="4" sm="4">
                    <v-rating
                        empty-icon="mdi-star"
                        full-icon="mdi-star"
                        half-icon="mdi-star-half-full"
                        half-increments
                        hover
                        color="#F3CD12"
                        length="5"
                        size="25"
                        readonly
                        :value="this.mediaStars"
                        class="ratingsCustom"
                    ></v-rating>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h2>{{this.user.name}}</h2>
                </v-col>
                <v-col cols="12">
                    <h2 class="progresso">Inicio: {{this.user.data_cadastro}}</h2>
                </v-col>
                <v-col cols="12">
                    <h2 class="progresso">Progresso: {{mediaPercentual}}%</h2>
                </v-col>
            </v-row>
            <v-row class="graficoDiagnostico">
                <v-col cols="12" align="center" justify="center">
                    <grafico-diagnostico :percentual="mediaPercentual"/>
                </v-col>
            </v-row>
                        
            <v-row class="d-flex">
                <v-col cols="12" md="6" v-for="diagnostico in lstDiagnostico" :key="diagnostico.diagnostico.id">
                    <card-diagnostico :software="diagnostico.software" :diagnostico="diagnostico.diagnostico" :detalhado="true"/>
                </v-col>
            </v-row>
            <v-row class="d-flex">
                <v-col cols="12" md="12">
                    <h4>Agora, você pode ir para a sessão de aulas e evoluir ainda mais!</h4>
                </v-col>
            </v-row>
            
            
        </div>
   </div>
</template>

<style scoped>

    .box-diagnostico {
        width: 100%;
        max-width: 887px;
        height: 600px;
        margin: 2% auto;
    }
    .img-nivel{
        width: 183.63px;
        height: 182.88px;
    }
    .link{
        cursor: pointer;
    }
    .img-arrow{
        width: 100%;
    }
   
    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 31.5224px;
        line-height: 38px;

        color: #4A4A4A;
    }
    .subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20.7074px;
        line-height: 31px;

        /* Color Principal */

        color: #004FF3;
    }
    .ratingsCustom{
        margin-top:-13px;
    }
    
    .btn-diagnostico-completo{
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important; 
        font-size: 22.0349px !important;
        line-height: 27px !important;
        color: #FFFFFF !important;
        background: #F99746 !important;
        border-radius: 18.3624px !important;
        min-height: 75.29px !important;
    }
    h2{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 25.8238px;
        line-height: 31px;
        color: #4A4A4A;
    }
    .percentual{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 22.1347px;
        line-height: 27px;
        color: #004FF3;
    }
    .progresso{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 22.1347px;
        line-height: 0px;
        margin-top:-10px;
        color: #004FF3;
    }
    
    @media only screen and (max-width: 600px) {
        .graficoDiagnostico{
            z-index: 99999;
            margin-top:-280px;
        }
        .ratingsCustom{
            margin-top:-14px;
        }
        .progresso{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12.1347px;
            line-height: 0px;
            margin-top:-25px;
            color: #004FF3;
    
        }
        h2{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 15.8238px;
            line-height: 21px;
            color: #4A4A4A;
        }
        .percentual{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 15.8238px;
            line-height: 17px;
            color: #004FF3;
        }
        .title{
            font-size: 20.5224px;
            line-height: 18px;
            margin-top:20px;
        }
        .subtitle{
            font-size: 12.7074px;
            line-height: 17px;
        }
        
        .btn-diagnostico-completo{
            font-size: 17.0349px !important;
            line-height: 27px !important;
            
        }
    }
</style>
<script>
    import client from '@/services/http/client/client';
    export default {
        name: "DiagnosticoScreen",
        data() {
            return {
                page: 1,
                lstDiagnostico: [],
                lstEnsinar: [],
                lstEvoluir: [],
                lstAprender: [],
                user: {
                    name: ''
                },
                loadingBtn: false,
                mediaPercentual: 0,
                mediaStars: 0
            }
        },
        mounted() {
            this.fetchDiagnosticos();
            this.fetchUser();
            //document.getElementsByClassName('v-navigation-drawer').setAttribute('style','z-index: 999999999 !important;')
        },
        created(){
            document.getElementById('css').setAttribute('style','background-color:#FFF !important;')
            
            
        },
        methods:{
            ordenarPorPercentual(a, b){
                return a.diagnostico.percentual_progresso < b.diagnostico.percentual_progresso ? 1 : 
                        a.diagnostico.percentual_progresso > b.diagnostico.percentual_progresso ? -1 :
                        0
            },
            fetchDiagnosticos(){
                client.get('/diagnostico-by-student?all=1')
                    .then( response => {
                        this.lstDiagnostico = response.data;
                        this.lstDiagnostico.sort(this.ordenarPorPercentual);

                        this.calculaMedia();

                    });
                    
            },
            fetchUser(){
                client.get('/auth/me')
                    .then( response => {
                        this.user = response.data.user;
                        
                    });
            },
            prev(){
                location.href = "/diagnostico";
            },  
            calculaMedia(){
                var totalPercentual = this.lstDiagnostico.map(item => parseFloat(item.diagnostico.percentual_progresso)).reduce((prev, curr) => prev + curr, 0);
                var totalItens = this.lstDiagnostico.length;
                this.mediaPercentual = totalPercentual / totalItens;

                this.mediaStars = Math.round(this.mediaPercentual / 20);
                this.mediaPercentual = this.mediaPercentual.toFixed(2)
            },
            dataFormatada(data){
                var dia  = data.getDate().toString(),
                diaF = (dia.length == 1) ? '0'+dia : dia,
                mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
                mesF = (mes.length == 1) ? '0'+mes : mes,
                anoF = data.getFullYear();
                return diaF+"/"+mesF+"/"+anoF;
            }
            
        }

    }
</script>