<template>
    <div class="container pt-4">
        <div class="row">
            <div class="box-login">
                <div class="row text-center">
                    <div class="col-12">
                        <img src="/assets/img/logo-white.png" alt="Logotipo branco" class="img-logo">
                    </div>
                    <div class="col-12 mt-5">
                        <v-btn href="/login" block class="btn-warning">
                            DESEJO FAZER LOGIN
                        </v-btn>
                    </div>
                    <div class="col-12">
                        <v-btn 
                            href="/registro"
                            block
                            class="btn-cadastrar"
                        >
                            CADASTRAR
                        </v-btn>
                    </div>
                    <termos-screen/>
                </div>
            </div>
        </div>
       
   </div>
</template>

<style scoped>
    .btn-warning{
        background-color: #EF7D00 !important;
        color: #FFFFFF;
        border-color: #EF7D00 !important;
        height: 54.97px !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18.2891px;
        line-height: 34px;
        text-align: center;
    }
    .btn-cadastrar{
        height: 54.97px !important;
        border: 3.03097px solid #FFFFFF;
        border-radius: 10.1032px;
        background-color: transparent !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 18.2891px;
        line-height: 34px;
        text-align: center;
        color: #FFFFFF;
    }
    .btn-cadastrar::hover{
        background-color: transparent !important;
    }
</style>
<script>
    export default {
        name: "HomeScreen",
        data() {
            return {
                logo: "/img/logo-white.png",
            }
        },
        mounted(){

        },
        methods:{
           
        },
        created(){
            document.getElementById('css').setAttribute('style','background-color:#004ff3 !important;')
            
        },
       
    }
</script>