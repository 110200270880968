<template>
    <v-row>
        <v-col cols="6" sm="3" md="3" v-for="item in lstDiagnostico" :key="item.software.id" v-on:click="redirectTo(item.software.id)">
            <lista-software-card-lista-software :icone="item.software.icone" :qtdAulas="item.software.aulas.length" />
        </v-col>
    </v-row>
</template>

<style scoped>
   
</style>
<script>
export default {
    name: "ListaSoftware",
    data() {
        return {
            software_id: null
        }
    },
    mounted(){
    },
    methods:{
        redirectTo(software_id){
            location.href = "/selecao-nivel/"+software_id
        }
    },  
    props:{
        lstDiagnostico:{
            type: Array,
            required: true
        }
    }
}
</script>