<template>
    <v-card elevation="2" class="card-software mb-2">
        <v-row>
            <v-col cols="8">
                <v-img :src="icone" class="img-fluid image"/>
            </v-col>
            <v-col cols="4" class="text-center">
                <div class="card-qtd-aulas">
                    <v-row class="d-flex align-items-center">
                        <span>{{qtdAulas}}</span>
                    </v-row>
                    
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<style scoped>
    .card-software{
        border-radius: 17.3394px !important; 
        background-color: #98BCCA;
        cursor: pointer;
    }
    .card-qtd-aulas{
        display:flex; 
        width:30px; 
        height:30px; 
        text-align: center; 
        align-items: center;  
        border-radius: 50%; 
        background-color:#fff;
        color: #3E3E3E;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
    }
  
    .image{
        width:100px;
        height:100px;
    }
    @media only screen and (max-width: 600px) {
        .image{
            width:100px;
            height:100px;
        }
        .card-qtd-aulas{
            width:30px; 
            height:30px; 
            font-size: 12.2752px;
        }

        
    }
</style>
<script>
export default {
    name: "CardListaSoftware",
    data() {
        return {
           
        }
    },
    mounted(){
    },
    methods:{
    },  
    props:{
       icone:{
        type:String,
        required: true,
       },
       qtdAulas:{
        type:Number,
        required: true,
       } 
    }
}
</script>