<template>
    <div>
        <div class="container pt-4">
            <header-component :showBack="false"/>
            <v-col v-if="loadContatos">
                <b-alert variant="info" show class="text-center" >Até o momento, você não possui notificações</b-alert>
            </v-col>
            <v-col v-else
                v-for="contato in this.lstContatos"
                :key="contato.id"
                cols="12"
                :loading="loadingBtn"
                
            >
                <v-card class="card-mensagem" v-on:click="VerRespostas(contato.id, contato.respostas)">
                    <v-list two-line>
                        <template>
                            <v-list-item>
                                <v-row>
                                    <v-col cols="12" md="1" class="text-center">
                                        <v-list-item-avatar class="text-center">
                                            <img :src="contato.dados_aluno.foto"/>
                                        </v-list-item-avatar>    
                                    </v-col>
                                    <v-col cols="12" md="11">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-row>
                                                    <v-col md="11" cols="12">
                                                        <strong>{{contato.assunto}}</strong>
                                                    </v-col>
                                                    <v-col md="1" cols="12">
                                                        <v-btn icon v-on:click="VerRespostas(contato.id, contato.respostas)">
                                                            <font-awesome-icon icon="fa fa-eye" color="#0259F3" style="width:25px;"/>
                                                        </v-btn>
                                                        <b-badge class="bg-primary">
                                                            {{contato.respostas.length}}
                                                        </b-badge>
                                                        
                                                    </v-col>
                                                </v-row>
                                                
                                            </v-list-item-title>

                                            <div class="content-message">
                                                <v-row>
                                                    <v-col>
                                                        {{contato.mensagem}}
                                                    </v-col>
                                                </v-row>
                                                
                                                                                            
                                            </div>
                                        </v-list-item-content>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card>

                <b-modal size="lg" :hide-footer="true" :hide-header="true" :id="'contato_' + contato.id">
                    <inbox-respostas :respostas="contato.respostas" :contato="contato"/>
                </b-modal>  

            </v-col>
        </div>
    </div>
</template>
<style scoped>
    .content-message{
        font-size: 15px;
        color: darkslategray;
    }
    .card-mensagem{
        cursor: pointer;
        border-radius: 20px;
    }
    .card-mensagem:hover{
        border-color:#0259F3 !important;
        border: 1px solid;
        border-radius: 20px;

    }
</style>

<script>
import { mapActions } from 'vuex';
export default {
    name: "InboxScreen",
    data() {
        return {
            loadingBtn: false,
            lstContatos: [],
            loadContatos: true
        }
    },
    mounted(){
       
    },
    created(){
        require('../../../public/assets/css/home-aluno.css');
        this.readAllMessages().then( () => {
            this.loadMessages().then( () => {
                this.lstContatos = this.$store.getters['contatos'].filter(item => item.fale_conosco_id == null)
                this.loadContatos = this.lstContatos.length == 0
            });
            
        });
    },
    methods:{
        ...mapActions([
            'loadMessages',
            'readAllMessages'
        ]),

        VerRespostas(id,respostas){
            this.loadingBtn = true;
            if (respostas.length > 0) {
                this.$bvModal.show('contato_' + id)
            }
            
        }
    }
}
</script>