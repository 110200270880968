import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeScreen from '@/components/Home/HomeScreen'
import LoginScreen from '@/components/Home/LoginScreen'
import RegistroForm from '@/components/Registro/RegistroForm'
import Step1 from '@/components/Registro/Step1'
import EnsinoStep2 from '@/components/Registro/EnsinoStep2'
import SoftwareScreen from '@/components/Software/SoftwareScreen'
import SoftwareFinalizarScreen from '@/components/Software/SoftwareFinalizarScreen'
import DiagnosticoScreen from '@/components/Diagnostico/DiagnosticoScreen'
import DiagnosticoScreenDetalhado from '@/components/Diagnostico/DiagnosticoScreenDetalhado'
import HomeAlunoScreen from '@/components/Home/HomeAlunoScreen'
import ListaNivel from '@/components/Home/ListaNiveis/ListaNivel'
import ListaAulas from '@/components/Home/ListaAulas/ListaAulas'
import SalaDeAula from '@/components/SalaDeAula/SalaDeAula'
import CarouselEntrada from '@/components/CarouselEntrada/CarouselEntrada'
import beforeEach from '@/router/beforeEach'
import ListaAulasTopico from '@/components/Home/ListaAulasTopico/ListaAulasTopico.vue'
import PerfilAluno from '@/components/Perfil/PerfilAluno.vue'
import Inbox from '@/components/Contato/Inbox.vue'
import ValidaCodigo from '@/components/ValidaCodigo/ValidaCodigo.vue'
import ResetarSenha from '@/components/ResetarSenha/ResetarSenha.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeScreen
  },
  {
    path: '/login',
    name: 'login',
    component: LoginScreen
  },
  {
    path: '/registro',
    name: 'registro',
    component: RegistroForm
  },
  {
    path: '/step1',
    name: 'step1',
    component: Step1
  },
  {
    path: '/ensino',
    name: 'ensino',
    component: EnsinoStep2
  },
  {
    path: '/seleciona-softwares',
    name: 'seleciona-softwares',
    component: SoftwareScreen
  },
  {
    path: '/finaliza-seleciona-softwares',
    name: 'finaliza-seleciona-softwares',
    component: SoftwareFinalizarScreen
  },
  {
    path: '/diagnostico',
    name: 'diagnostico',
    component: DiagnosticoScreen
  },
  {
    path: '/diagnostico-detalhado',
    name: 'diagnostico-detalhado',
    component: DiagnosticoScreenDetalhado
  },
  {
    path: '/home',
    name: 'home-aluno',
    component: HomeAlunoScreen
  },
  {
    path: '/selecao-nivel/:software_id',
    name: 'selecao-nivel',
    component: ListaNivel
  },
  {
    path: '/lista-aulas/:software_id/:nivel_id',
    name: 'selecao-aula',
    component: ListaAulas
  },
  {
    path: '/sala-de-aula/:aula_id/:software_id/:topico_id',
    name: 'sala-de-aula',
    component: SalaDeAula
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: CarouselEntrada
  },
  {
    path: '/busca-aulas/:topico_id',
    name: 'selecao-topico-aula',
    component: ListaAulasTopico
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: PerfilAluno
  },
  {
    path: '/contato',
    name: 'contato',
    component: Inbox
  },
  {
    path: '/validar-codigo/:origem',
    name: 'validar-codigo',
    component: ValidaCodigo
  },
  {
    path: '/recuperar-senha',
    name: 'recuperar-senha',
    component: ResetarSenha
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(beforeEach)

export default router
