<template>
    <div>
        <v-sheet
            color="grey lighten-4"
            class="pa-4"
            align="center"
        >
            <v-row>
                <v-col cols="12">
                    <v-avatar size="80" class="mt-3">
                        <img :src="this.$store.getters['user'].foto"/>
                    </v-avatar>
                </v-col>
                <v-col cols="12">
                    <span style="font-size: 15px;">Bem-vindo(a) 😀, <br> {{this.$store.getters['user'].name}}</span>
                </v-col>
                <v-col cols="4">
                    <v-btn icon  title="Notificações" v-on:click="redirectTo('/contato')">
                        <v-avatar color="indigo">
                            <font-awesome-icon icon="fa-regular fa-bell" color="#0259F3" style="width:25px;"/>
                        </v-avatar>
                        <span id="notificacoes">{{ this.$store.getters['contatos'].filter(item => item.visualizada == 0 && item.destinatario_id == this.$store.getters['user'].id).length }}</span>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <v-btn icon  title="Contato" v-on:click="contato()">
                        <v-avatar color="indigo">
                            <font-awesome-icon icon="fa-regular fa-envelope" color="#0259F3" style="width:25px;"/>
                        </v-avatar>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <v-btn icon  title="Editar Perfil" v-on:click="redirectTo('/perfil')">
                        <v-avatar color="indigo">
                            <font-awesome-icon icon="fa-solid fa-edit" color="#0259F3" style="width:25px;"/>
                        </v-avatar>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-sheet>
        <v-list>
            <drawer-menu-item redirectUrl="/home" icon="mdi-home" title="Home" />
            <drawer-menu-item redirectUrl="/diagnostico" icon="mdi-clipboard-search-outline" title="Diagnóstico" />
            <drawer-menu-item redirectUrl="/home" icon="mdi-school-outline" title="Plano de estudos" />
            <drawer-menu-item redirectUrl="/home" icon="mdi-monitor-account" title="Aulas" />
            <v-list-item v-on:click="offline()">
                <v-list-item-icon>
                    <v-icon style="color:#0259F3 !important;">mdi-close</v-icon>
                </v-list-item-icon>
                <v-list-item-title style="margin-left: 10px;">
                    Sair
                </v-list-item-title>
            </v-list-item>
        </v-list>
        <b-modal :hide-footer="true" :hide-header="true" id="contato" class="bg-grey">
            <contato-aluno></contato-aluno>
        </b-modal>  
    </div>
</template>
<style>
.v-list-item__icon{
    color: red;
}
</style>
<script>
import client from '@/services/http/client/client';
import { mapActions } from 'vuex';
  export default {
    name: "DrawerMenuList",
    data: () => (
        { 
            drawer: null ,
            user:{},
        }
    ),
    mounted(){
        this.user = this.$store.getters['user']
        this.loadMessages()
    },
    methods:{
        ...mapActions([
            'loadMessages',
        ]),
        redirectTo(url){
            location.href = url;
        },
        offline(){
            client.post('/auth/logout')
                .then(() => {
                    localStorage.removeItem('token')
                    location.href = '/login';
                })
                .catch(() => {
                    localStorage.removeItem('token')
                    location.href = '/login';
                })
                .finally(() => {
                    location.href = '/login';
                });
        },
        contato(){
            this.$bvModal.show('contato')
        },
      }
  }
</script>
