<template>
    <div>
        <div class="container pt-4">
            <header-component :showBack="true" :urlBack="'/busca-aulas/' + this.topicoId"/>
            <div class="box-carousel">
                <v-col cols="12">
                    <span class="titulo-aula">{{this.aula.titulo}}</span>
                </v-col>
                <v-col cols="12">
                    <iframe 
                        :loading="loadingVideo"
                        width="100%" height="442px"
                        :src="this.aula.link"
                        :title="this.aula.titulo"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        id="previewVideo"
                        allowfullscreen>
                    </iframe>
                </v-col>
                
                <v-row cols="12" class="d-flex text-left">
                    <v-col cols="4" md="2">
                        <v-btn icon v-on:click="curtir(1)" title="Gostei">
                            <font-awesome-icon icon="fa-solid fa-heart" size="2x" :color="this.colorHeart"/>
                        </v-btn>
                    </v-col>
                    <v-col cols="4" md="2">
                        <v-btn icon v-on:click="curtir(0)" title="Não Gostei"> 
                            <font-awesome-icon icon="fa-solid fa-heart-broken" size="2x" :color="this.colorBrokenHeart"/>
                        </v-btn>
                    </v-col>
                    <v-col cols="4" md="2">
                        <v-btn icon title="Denunciar Conteúdo">
                            <font-awesome-icon icon="fa-regular fa-bell" size="2x" :color="this.colorBells"/>
                        </v-btn>
                    </v-col>
                </v-row>
                <hr color="#C0C0C0"/>
                <v-row>
                   <b-col cols="2"></b-col>
                   <v-col cols="10">
                        <v-row class="mb-2 mt-2 text-left">
                            <span class="usuario-postou">{{(this.aula.professor != null) ? this.aula.professor.name : 'Administrador'}}</span>
                        </v-row>
                        <v-row class="mt-2 text-left">
                            <span v-html="this.aula.descricao" class="conteudo-aula"></span>
                        </v-row>
                   </v-col>
                </v-row>
                <v-col cols="12">
                    <v-btn primary block :loading="loadingBtn" v-on:click="MarcaAprendizado()" >
                        Aprendeu? Então clique aqui e atualize seu Autodiagnóstico
                    </v-btn>
                </v-col>
            </div>
        </div>
        
    </div>
</template>
<style scoped>
    .usuario-postou{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        color: #004FF3;

    }
    .conteudo-aula{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;

        color: #6A6A6A;
    }
   .box-carousel {
        width: 100%;
        max-width: 700px;
        height: 600px;
        margin: 2% auto;
    }
    .titulo-aula{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 30px;
        color: #004FF3;
    }
    .acoes-text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 10.5531px;
        color: #707070 !important;
    }
    @media only screen and (max-width: 600px) {
        .titulo-aula{
            font-size: 18px;
            line-height: 15px;
        }
        .acoes-text{
            font-size: 9.3531px;
            color: #707070 !important;
        }
    }
</style>
<script>
import client from '@/services/http/client/client';
export default {
    name: "SalaDeAula",
    data() {
        return {
            aula:{
                id:null,
                titulo:null,
                link:null
            },
            aulaId: 0,
            softwareId: 0,
            topicoId:0,
            loadingBtn:false,
            loadingVideo: true,
            avaliacao: {},
            colorHeart: '#C0C0C0',
            colorBrokenHeart: '#C0C0C0',
            colorBells: '#C0C0C0',
            professor: {}
        }
    },
    mounted(){
        //'/sala-de-aula/:aula_id/:software_id/:topico_id',
        this.aulaId = this.$route.params.aula_id
        this.softwareId = this.$route.params.software_id
        this.topicoId = this.$route.params.topico_id
        this.BuscaAulas();
        this.fetchLikes();
    },
    created(){
        require('../../../public/assets/css/home-aluno.css');
    },
    methods:{
        BuscaAulas(){
            client.get('/aulas/'+this.aulaId)
                .then( response => {
                    this.aula = response.data;
                    console.log(this.aula)
                });
        },
        MarcaAprendizado(){
            this.loadingBtn = true;
            this.$swal.fire({
                icon: 'question',
                reverseButtons: false,
                title: 'CONSEGUIU APRENDER ESSE TÓPICO?',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showLoaderOnConfirm: true,
            }).then((result) => {
                var lstAulasAssistidas = [];
                if ( result.isConfirmed ) {
                    lstAulasAssistidas.push(this.aula);

                    client.post('/aulas/assistida', {
                        lstAulas: lstAulasAssistidas,
                        software_id: this.softwareId,
                        topico_id: this.topicoId,
                        aula_id: this.aulaId,
                        sala: true,
                        forceDelete: false
                    })
                    .then(() => {
                        this.$swal.fire({
                            icon: 'success',
                            reverseButtons: true,
                            title: 'Aprendizado registrado com sucesso!',
                            confirmButtonText: 'Ok',
                            showLoaderOnConfirm: true,
                        }).then(() => {
                            this.loadingBtn = false;
                            location.href = '/busca-aulas/' + this.topicoId
                        })
                    })
                    .catch(() => {
                        this.$swal.fire({
                            icon: 'error',
                            reverseButtons: true,
                            title: 'Ocorreu um erro ao gravar a visualização da Aula',
                            confirmButtonText: 'Ok',
                            showLoaderOnConfirm: true,
                        }).then(() => {
                            this.loadingBtn = false;
                        })  
                    })
                    .finally(() => {
                        this.loadingBtn = false;
                    });
                } else {
                    this.$swal.fire({
                        icon: 'success',
                        reverseButtons: true,
                        title: 'OK! Não registraremos o seu aprendizado nesta aula.',
                        confirmButtonText: 'Ok',
                        showLoaderOnConfirm: true,
                    }).then(() => {
                        this.loadingBtn = false;
                    })
                }
                
            })
        },
        changeIconsColors(){
            console.log(this.avaliacao)
            if (this.avaliacao.like === 1) {
                this.colorHeart = '#FF0000'
                this.colorBrokenHeart = '#C0C0C0'
            } 
            if (this.avaliacao.unlike === 1) {
                this.colorHeart = '#C0C0C0'
                this.colorBrokenHeart = '#FF0000'
            }
        },
        fetchLikes(){
            client.get('/aulas/likes/' + this.topicoId + '/' + this.aulaId)
            .then((response) => {
                this.avaliacao = response.data
                this.changeIconsColors()
            })
        },
        curtir(status){
            client.post('/aulas/avaliar', {
                topico_id: this.topicoId,
                aula_id: this.aulaId,
                curtir:status
            })
            .then((response) => {
                this.avaliacao = response.data
                this.changeIconsColors()
            })
        }
    },  
}
</script>