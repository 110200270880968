<template>
    <div>
        <div class="container pt-4">
            <header-component :showBack="false"/>

            <v-col
                v-for="resposta in respostas"
                :key="resposta.id"
                cols="12"
            >
                <v-card>
                    <v-list two-line>
                        <template>
                            <v-list-item>
                                <v-col cols="1" class="text-center">
                                    <v-list-item-avatar class="text-center">
                                        <img :src="resposta.respondido_por.foto != null ? resposta.respondido_por.foto : '/assets/img/software/sem-foto.png'"/>
                                    </v-list-item-avatar>    
                                </v-col>
                                <v-col cols="11">
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            <v-row>
                                                <v-col>
                                                    {{resposta.mensagem}}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    Respondido em: {{resposta.criado_em}}
                                                </v-col>
                                                <v-col>
                                                    Respondido por: {{resposta.respondido_por.name}}
                                                </v-col>
                                            </v-row>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card>
                
            </v-col>
            <v-row>
                <v-col v-if="showSuccess">
                    <b-alert variant="success" show >{{alertMessage}}</b-alert>
                </v-col>
                <v-col  v-if="showError">
                    <b-alert variant="danger" show>{{alertMessage}}</b-alert>
                </v-col>
            </v-row>
            <v-row>
                <b-form @submit.prevent="formSubmit()">
                    <v-col cols="12" md="12">
                        <b-form-textarea
                            id="textarea"
                            v-model="novaMensagem.mensagem"
                            placeholder="Responder"
                            rows="3"
                            max-rows="6"
                            required
                        ></b-form-textarea>
                    </v-col>
                </b-form>
            </v-row>
            <v-card-actions>
                
                
                <v-spacer></v-spacer>
                <b-button :loading="loadingBtn" variant="primary" class="text-white" type="submit" v-on:click="formSubmit()">
                    Salvar
                </b-button>
                <v-btn
                    v-on:click="fecharModal()"
                >
                    Fechar
                </v-btn>
    
            </v-card-actions>
        </div>
       
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import client from '@/services/http/client/client';
export default {
    name: "InboxScreen",
    data() {
        return {
            novaMensagem: {
                mensagem: '',
                fale_conosco_id: 0
            },
            loadingBtn:false,
            showSuccess:false,
            alertMessage: '',
            showError:false,
            reply: false
        }
    },
    props:{
        respostas: [],
        contato: {}
    },
    mounted(){
        this.novaMensagem.fale_conosco_id = this.contato.id;
    },
    created(){
        require('../../../public/assets/css/home-aluno.css');
    },
    methods:{
        ...mapActions([
            'loadMessages',
        ]),
        fecharModal(){
            this.$bvModal.hide('contato_' + this.contato.id)
        },
        formSubmit(){
                this.loadingBtn = true;
                if (this.mensagem != "" && this.assunto != "") {
                    client.post('contato',{
                        mensagem: this.novaMensagem.mensagem,
                        fale_conosco_id: this.novaMensagem.fale_conosco_id
                    })
                    .then( (response) => {
                        this.loadingBtn = false;
                        this.showError = !(response.data.type != "error")
                        this.showSuccess = (response.data.type != "error")
                        this.alertMessage = response.data.msg
                        location.reload()
                        //this.fecharModal()
                    })
                    .catch(function (error) {
                        this.alertMessage = error
                        this.showError = true
                        this.showSuccess = false
                        this.loadingBtn = false;
                    })
                } else {
                    this.loadingBtn = false;
                    this.alertMessage = 'Os campos assunto e mensagem são obrigatórios'
                    this.showError = true
                    this.showSuccess = false
                }
                
            }
    }
}
</script>