<template>
  <div id="app">
    <v-navigation-drawer
      v-model="drawer"
      color="grey lighten-4"
      app
      v-if="showNavBar"
    >
      <drawer-menu-list />
    </v-navigation-drawer>

    <v-app-bar app style="background-color: #0259F3; margin-bottom: 10px;" v-if="showNavBar" justify="space-between" >
      <v-col cols="11">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      </v-col>
      <v-col cols="1">
        <img src="/assets/img/logo-white.png" style="margin-left:-60px;" alt="Logotipo branco" width="80px">
      </v-col>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </div>
</template>
<script>
  import client from '@/services/http/client/client';
  export default {
    data: () => (
      { 
        drawer: null,
        isLogged: false,
        dontShowNavBarList:['registro','step1','ensino','welcome','seleciona-softwares','finaliza-seleciona-softwares']
      }
    ),

    mounted(){
      this.me()
      
    },
    computed: {
      showNavBar(){
        const routeExists = this.dontShowNavBarList.indexOf(this.$store.getters['route']) >= 0;
        console.log(routeExists,this.$store.getters['route']);
        return !routeExists && this.isLogged
      }
    },  
    methods:{
      me(){
        client.get('/auth/me')
        .then(response => {
          this.user = response.data.user
          let token = localStorage.getItem('token')
          
          this.isLogged = (this.user.name != "") && (this.user.name) && token

        })
        .catch(() => {
        })
      },
      offline(){
        client.post('/auth/logout')
            .then(() => {
                localStorage.removeItem('token')
                location.href = '/login';
            })
            .catch(() => {
                localStorage.removeItem('token')
                location.href = '/login';
            })
            .finally(() => {
                location.href = '/login';
            });
        }
    }
  }
</script>
