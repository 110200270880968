<template>
    <div class="container pt-4">
        <div class="row">
            <div class="col-12 d-flex flex-row-reverse bd-highlight">
                <img src="assets/img/logo-blue.svg" class="img-logo-registro" alt="Logotipo branco"> 
            </div>
        </div>
        <div class="row">
            <div class="box-register">
                <v-row>
                    <v-col cols="12">
                        <h3 class="titulo-registro">Olá, seja bem-vindo(a) ao nosso app</h3>
                    </v-col>
                    <v-col cols="12">
                        <span class="descricao-registro">Para acessar nossa aplicação digite seu login e senha abaixo.</span>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            :hide-details="true"
                            :light="true"
                            label="E-mail"
                            v-model="email"
                            outlined
                            type="email"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            :hide-details="true"
                            :light="true"
                            label="Senha"
                            v-model="password"
                            outlined
                            type="password"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-btn 
                            :x-large="true" 
                            class="btn-lg col-12 btn-warning" 
                            :loading="loadingBtn" 
                            v-on:click="efetuaLogin()"
                        >
                            ACESSAR
                        </v-btn>
                    </v-col>
                    <div class="col-12">
                        <v-btn 
                            href="/registro"
                            block
                            class="btn-cadastrar"
                        >
                            CADASTRAR
                        </v-btn>
                    </div>
                    <div class="col-12">
                        <v-btn 
                            href="/recuperar-senha"
                            block
                            class="btn-cadastrar"
                        >
                            ESQUECI MINHA SENHA
                        </v-btn>
                    </div>
                   
                </v-row>
            
            </div>
        </div>
        
    </div>
</template>
<style scoped>
    .btn-warning{
        background-color: #EF7D00 !important;
        color: #FFFFFF;
        border-color: #EF7D00 !important;
    }
    .v-text-field--outlined >>> fieldset {
        border-color: 1.33321px solid #CCC;
    }
    .v-input--is-focused >>> fieldset {
        border-color: 1.33321px solid #0A83F3;
    }
    .btn-cadastrar{
        height: 54.97px !important;
        border: 1.99981px solid #004FF3;
        border-radius: 6.66603px;
        background-color: transparent !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 18.2891px;
        line-height: 34px;
        text-align: center;
        color: #004FF3;
    }
    .btn-cadastrar::hover{
        background-color: transparent !important;
    }
</style>
<script>
    import client from '@/services/http/client/client';
    import { mapActions } from 'vuex';
    export default {
        name: "LoginScreen",
        data () {
            return {
                email:null,
                password:null,
                loadingBtn:false,
            };
        },
        created(){
            document.getElementById('css').setAttribute('style','background-color:#FFF !important;')
        },
        methods:{
            ...mapActions([
                'setToken',
                'setUser'
            ]),
            efetuaLogin(){
                client.post('/auth/login', {
                    email: this.email,
                    password:this.password,
                })
                .then(response => {
                    if ( response.status === 200 && response.data.message !== "") {
                        localStorage.setItem('email', this.email)
                        location.href = '/validar-codigo/register'
                    }
                    this.setToken('Bearer ' + response.data.access_token)
                    localStorage.setItem('token', 'Bearer ' + response.data.access_token)
                    this.setUser(response.data.me)
                    this.checaPrimeiroAcesso(response.data.me);
                })
                .catch(() => {
                    
                    this.$swal.fire({
                        icon: 'error',
                        reverseButtons: true,
                        title: 'Usuário e/ou senha não conferem',
                        confirmButtonText: 'Ok',
                        showLoaderOnConfirm: true,
                    }).then(() => {
                        this.loadingBtn = false;
                    })
                    
                })
                .finally(() => {
                    this.loadingBtn = false;
                });
            },
            checaPrimeiroAcesso(me){
                if((Boolean(me.primeiro_acesso)) && (Boolean(me.preencheu_diagnostico))){
                    location.href = '/home'
                } else if((!(me.primeiro_acesso)) && (!(me.preencheu_diagnostico))){
                    location.href = '/welcome'
                } else if ((Boolean(me.primeiro_acesso)) && (!(me.preencheu_diagnostico))){
                    location.href = '/seleciona-softwares'
                }  
            }
        },
    }
</script>
