<template>
  <div class="container pt-4">
      <div class="row">
          <div class="col-12 d-flex flex-row-reverse bd-highlight">
              <img src="../../assets/img/logo-white.png" class="img-logo-registro" alt="Logotipo branco"> 
          </div>
      </div>
      <div class="row">
          <div class="box-register">
              <v-row>
                  <v-col cols="12" class="text-center">
                      <h3 class="titulo-registro">Nova senha!</h3>
                  </v-col>
                  <v-col cols="12">
                      <span class="descricao-registro">Digite a nova senha:</span>
                  </v-col>
                  <v-col cols="12">
                      <v-text-field
                          :hide-details="true"
                          :dark="true"
                          :light="true"
                          label="Senha"
                          v-model="password"
                          outlined
                          type="password"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        :hide-details="true"
                        :dark="true"
                        :light="true"
                        label="Confirmação de senha"
                        v-model="confirmPassword"
                        outlined
                        type="password"
                    ></v-text-field>
                </v-col>
                  <v-col cols="12">
                      <v-btn :x-large="true" class="btn-lg col-12 btn-warning" :loading="loadingBtn" v-on:click="validar()">
                          ALTERAR
                      </v-btn>
                      
                  </v-col>
              </v-row>
          </div>
          
      </div>
  </div>
</template>
<style scoped>
  .btn-warning{
      background-color: #EF7D00 !important;
      color: #FFFFFF;
      border-color: #EF7D00 !important;
  }
  .titulo-registro, .descricao-registro{
      color: #fff;
  }
</style>
<script>
  import client from '@/services/http/client/client';
  export default {
    name: "NovaSenha",
    data () {
        return {
            email: null,
            password:null,
            confirmPassword:null,
            loadingBtn:false
        };
    },
    mounted() {
        this.email = localStorage.getItem('email')
    },
    methods:{
        validarSenha() {
            if (this.password !== this.confirmPassword) {
                this.$swal.fire({
                    icon: 'error',
                    reverseButtons: true,
                    title: 'Senhas não conferem',
                    confirmButtonText: 'Ok',
                    showLoaderOnConfirm: true,
                }).then(() => {
                    this.loadingBtn = false;
                })
            } else {
                this.validar();
            }
        },
        validar(){
            this.loadingBtn = true;
            client.post('auth/login/reset/new-password', {
                email: this.email,
                new_password: this.password
            })
            .then((response) => {
                if (response.status === 200) {
                    location.href = '/login'
                }
            })
            .catch(() => {
                this.$swal.fire({
                    icon: 'error',
                    reverseButtons: true,
                    title: 'Código inválido',
                    confirmButtonText: 'Ok',
                    showLoaderOnConfirm: true,
                }).then(() => {
                    this.loadingBtn = false;
                })
            })
            .finally(() => {
                this.loadingBtn = false
                //window.location.href = '/step1'
            })
        },
    },
    created(){
      document.getElementById('css').setAttribute('style','background-color:#004ff3 !important;')
    }
  }
</script>