<template>
  <div>
    <div class="container pt-4" v-if="! valido">
        <div class="row">
            <div class="col-12 d-flex flex-row-reverse bd-highlight">
                <img src="../../assets/img/logo-white.png" class="img-logo-registro" alt="Logotipo branco"> 
            </div>
        </div>
        <div class="row">
            <div class="box-register">
                <v-row>
                    <v-col cols="12" class="text-center">
                        <h3 class="titulo-registro">Confirmação de E-mail!</h3>
                    </v-col>
                    <v-col cols="12">
                        <span class="descricao-registro">Enviamos um código de confirmação para seu e-mail, digite abaixo:</span>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            :hide-details="true"
                            :dark="true"
                            :light="true"
                            label="Código de confirmação"
                            v-model="code"
                            outlined
                            type="text"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-btn :x-large="true" class="btn-lg col-12 btn-warning" :loading="loadingBtn" v-on:click="validar()">
                            VALIDAR
                        </v-btn>
                        
                    </v-col>
                </v-row>
            </div>
            
        </div>
    </div>
    <nova-senha v-if="showNewPassword"/>
  </div>
</template>
<style scoped>
  .btn-warning{
      background-color: #EF7D00 !important;
      color: #FFFFFF;
      border-color: #EF7D00 !important;
  }
  .titulo-registro, .descricao-registro{
      color: #fff;
  }
</style>
<script>
  import client from '@/services/http/client/client';
  export default {
    name: "ValidaCodigo",
    data () {
        return {
          email: '',
          code:'',
          loadingBtn:false,
          origem:'',
          valido: false,
          user:null
        };
    },
    mounted(){
      this.origem = this.$route.params.origem
      this.email  = localStorage.getItem('email')
    },
    methods:{
      validar(){
        this.loadingBtn = true
        let url = '/auth/register/confirmation';
        if (this.origem === 'reset') {
          url = 'auth/login/reset/confirmation'
        }
        client.post(url, {
            email:this.email,
            code:this.code,
        })
        .then(() => {
            if (this.origem === 'register') {
              window.location.href = '/step1'
            } else {
              this.valido = true
            }
          }
        )
        .catch((error) => {
          if (error.response.status === 404) {
            this.$swal.fire({
              icon: 'error',
              reverseButtons: true,
              title: 'Código digitado é inválido!',
              confirmButtonText: 'Ok',
              showLoaderOnConfirm: true,
            }).then(() => {
                this.loadingBtn = false;
            })
          } else {
            this.$swal.fire({
              icon: 'error',
              reverseButtons: true,
              title: 'Ocorreu um erro ao validar seu código!',
              confirmButtonText: 'Ok',
              showLoaderOnConfirm: true,
            }).then(() => {
                this.loadingBtn = false;
            })
          }
          
        })
        .finally(() => {
            this.loadingBtn = false;
        });
      },
    },
    computed:{
      showNewPassword(){
        return (this.origem === 'reset' && this.valido)
      }
    },
    created(){
      document.getElementById('css').setAttribute('style','background-color:#004ff3 !important;')
    }
  }
</script>