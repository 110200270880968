<template>
    <div>
        <div class="container pt-4 mb-5">
            <div class="row">
                <div class="d-flex justify-content-between">
                    <div class="d-flex justify-content-between link">
                        
                    </div>
                    <img src="assets/img/logo-blue.svg" class="img-logo-registro" alt="Logotipo branco">
                </div>
            </div>
            <div class="box-carousel">
                <v-row>
                    <h3 class="title">Resultado do <br/> Diagnóstico</h3>
                </v-row>
                <v-row >
                    <v-col cols="12" md="4" sm="4" align="center" justify="center">
                        <imagem-nivel-diagnostico :star1="this.stars[0]" :star2="this.stars[1]" :star3="this.stars[2]" :star4="this.stars[3]" :star5="this.stars[4]"/>
                    </v-col>
                    <v-col cols="12" md="8" sm="8" align="center" justify="center">
                        <v-row>
                            <span class="subtitle">Você está com {{mediaPercentual}}% do seu <br/>objetivo completo!</span>
                        </v-row>
                        <v-row>
                            <v-rating
                                empty-icon="mdi-star"
                                full-icon="mdi-star"
                                half-icon="mdi-star-half-full"
                                half-increments
                                hover
                                color="#F3CD12"
                                length="5"
                                size="35"
                                readonly
                                :value="this.mediaStars"
                            ></v-rating>
                        </v-row>
                        <v-row>
                            <v-container>
                                <v-btn block class="btn-diagnostico-completo" v-on:click="DiagnosticoCompleto()">Ver diagnóstico completo</v-btn>
                            </v-container>
                        </v-row>
                    </v-col>
                </v-row>
                            
                <v-row class="d-flex">
                    <v-col cols="12" md="12">
                        <card-software-diagnostico v-if="this.lstEnsinar.length > 0" :title="'O que você pode ensinar'" :diagnosticos="this.lstEnsinar"/>
                        <card-software-diagnostico v-if="this.lstEvoluir.length > 0" :title="'O que você precisa evoluir'" :diagnosticos="this.lstEvoluir"/>
                        <card-software-diagnostico v-if="this.lstAprender.length > 0" :title="'O que você precisa aprender'" :diagnosticos="this.lstAprender"/>
                    </v-col>
                </v-row>
            </div>
        </div>
        
    </div>
</template>
<style scoped>
    .img-nivel{
        width: 183.63px;
        height: 182.88px;
    }
    .link{
        cursor: pointer;
    }
    .img-arrow{
        width: 100%;
    }
   
    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 31.5224px;
        line-height: 38px;

        color: #4A4A4A;
    }
    .subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20.7074px;
        line-height: 31px;

        /* Color Principal */

        color: #004FF3;
    }
    
    .btn-diagnostico-completo{
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important; 
        font-size: 22.0349px !important;
        line-height: 27px !important;
        color: #FFFFFF !important;
        background: #F99746 !important;
        border-radius: 18.3624px !important;
        min-height: 75.29px !important;
    }
    
    @media only screen and (max-width: 600px) {
        .title{
            font-size: 20.5224px;
            line-height: 18px;
            margin-top:20px;
        }
        .subtitle{
            font-size: 12.7074px;
            line-height: 17px;
        }
        
        .btn-diagnostico-completo{
            font-size: 17.0349px !important;
            line-height: 27px !important;
            
        }
    }
</style>
<script>
    import client from '@/services/http/client/client';
    export default {
        name: "DiagnosticoScreen",
        data() {
            return {
                page: 1,
                lstDiagnostico: [],
                lstEnsinar: [],
                lstEvoluir: [],
                lstAprender: [],
                loadingBtn: false,
                stars:[
                    '#fff',
                    '#fff',
                    '#fff',    
                    '#fff',
                    '#fff',
                ],
                mediaPercentual: 0,
                mediaStars: 0
            }
        },
        mounted() {
            this.fetchDiagnosticos();
        },
        created(){
            document.getElementById('css').setAttribute('style','background-color:#FFF !important;')
        },
        methods:{
            ordenarPorPercentual(a, b){
                return a.diagnostico.percentual_progresso < b.diagnostico.percentual_progresso ? 1 : 
                        a.diagnostico.percentual_progresso > b.diagnostico.percentual_progresso ? -1 :
                        0
            },
            fetchDiagnosticos(){
                client.get('/diagnostico-by-student?all=1')
                    .then( response => {
                        this.lstDiagnostico = response.data;
                        
                        this.lstEnsinar = this.lstDiagnostico.filter(item => parseFloat(item.diagnostico.percentual_progresso) >= 70);
                        this.lstEvoluir = this.lstDiagnostico
                                                .filter(item => parseFloat(item.diagnostico.percentual_progresso) >= 1 &&  
                                                        parseFloat(item.diagnostico.percentual_progresso) <= 69);
                        this.lstAprender = this.lstDiagnostico
                                                .filter(item => parseFloat(item.diagnostico.percentual_progresso) == 0 );
                        
                        this.lstEnsinar.sort(this.ordenarPorPercentual);
                        this.lstEvoluir.sort(this.ordenarPorPercentual);
                        this.lstAprender.sort(this.ordenarPorPercentual);
                        this.calculaMedia();

                    });
                    
            },
            DiagnosticoCompleto(){
                location.href = "/diagnostico-detalhado";
            },
            calculaMedia(){
                var totalPercentual = this.lstDiagnostico.map(item => parseFloat(item.diagnostico.percentual_progresso)).reduce((prev, curr) => prev + curr, 0);
                var totalItens = this.lstDiagnostico.length;
                this.mediaPercentual = totalPercentual / totalItens;

                this.mediaStars = Math.round(this.mediaPercentual / 20);
                for(var i = 0; i <= this.mediaStars-1; i++){
                    this.stars[i] = '#F3CD12';
                }
                this.mediaPercentual = this.mediaPercentual.toFixed(2)
            }
            
        }

    }
</script>